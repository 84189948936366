import { authAdminActions } from "../slice/authAdminSlice";
import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

export const AdminAuthLogin = ({ username, password }) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        "https://67.223.117.107/api/admin/login",
        { username, password }
      );
      const data = response.data;
      // console.log("adminn", response);

      Cookies.set("userToken", data.token, { expires: 1 });
      Cookies.set("admin", data.data, { expires: 1 });

      dispatch(authAdminActions.setAdmin(data.data));
      dispatch(authAdminActions.setToken(data.token));

      toast.success("Admin logged in successfully.");
      return data;
    } catch (error) {
      dispatch(authAdminActions.loginFailure(error.message));
      console.error(error, error.message);
      toast.error("Please try again!");

      throw error;
    }
  };
};

export const logoutAdmin = () => {
  return (dispatch) => {
    dispatch(authAdminActions.logout());
    Cookies.remove("userToken");
    Cookies.remove("admin");
    return;
  };
};
