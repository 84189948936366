import Card from "react-bootstrap/Card";
import { IoIosPricetags } from "react-icons/io";
import { IoStarSharp } from "react-icons/io5";
import { useSelector } from "react-redux";

const SubcategoriesCard = () => {
  const { productsCount, recommendedProducts } = useSelector(
    (state) => state.product
  );
  return (
    <article className="d-flex  mt-5 gap-5">
      <article className="d-flex flex-column">
        <span className="text-muted">Products</span>
        <Card
          className="d-flex flex-row justify-content-between align-items-center"
          style={{ width: 210, height: 80 }}
        >
          <Card
            className="bg-dark  text-warning-color-main"
            style={{ width: 80, height: 80 }}
          >
            <Card.Body className="rounded">
              <Card.Title className="text-center display-6 d-flex justify-content-center">
                <IoIosPricetags
                  style={{ width: 40, height: 40, color: "#ff6016" }}
                />
              </Card.Title>
            </Card.Body>
          </Card>
          <article>
            <Card.Title className="text-center p-3  display-5 text-dark">
              {productsCount}
            </Card.Title>
          </article>
        </Card>
      </article>

      <article className="d-flex flex-column">
        <span className="text-muted">Recommended Products</span>
        <Card
          className="d-flex flex-row justify-content-between align-items-center"
          style={{ width: 210, height: 80 }}
        >
          <Card
            className="bg-dark  text-warning-color-main"
            style={{ width: 80, height: 80 }}
          >
            <Card.Body className="rounded">
              <Card.Title className="text-center display-6 d-flex justify-content-center">
                <IoStarSharp
                  style={{ width: 40, height: 40, color: "#ff6016" }}
                />
              </Card.Title>
            </Card.Body>
          </Card>
          <article>
            <Card.Title className="text-center p-3  display-5 text-dark">
              {recommendedProducts}
            </Card.Title>
          </article>
        </Card>
      </article>
    </article>
  );
};

export default SubcategoriesCard;
