import React from "react";
import "./styles/cart.css";
import { useSelector, useDispatch } from "react-redux";

const ConfirmCart = () => {
  var cartDetails = useSelector((state) => state.cart.carts);

  return (
    <>
      {cartDetails.map((each) => (
        <article className="cart-shop-main-component" key={each.productId}>
          <article className="d-flex gap-2 mt-2">
            <img
              src={each.productData.images[0].path}
              style={{ width: "100px", height: "100px", borderRadius: "5px" }}
              alt="product"
            />
            <article className="d-flex justify-content-between w-100">
              <article className="d-flex flex-column justify-content-between">
                <article className="product-description-details">
                  <span>{each.productData?.name}</span>
                  <br></br>
                  {/* {console.log("subcategory ", each)} */}
                  <span className="text-muted">
                    {each.productData.subcategoryId?.name}
                  </span>
                </article>
                <article className="d-flex m-0">
                  {each.chosenSize ? (
                    <article className="d-flex m-0">
                      <p className="text-muted m-0">Size&nbsp;</p>
                      <p className="m-0">
                        {each.chosenSize}
                        {" / "}
                      </p>
                    </article>
                  ) : null}
                  {each.chosenColor ? (
                    <article className="d-flex m-0">
                      &nbsp;
                      <p className="text-muted m-0">Color&nbsp;</p>
                      <p className="m-0">{each.chosenColor}</p>
                    </article>
                  ) : null}
                </article>
                <h6 className="color-for-quantity-product">x{each.quantity}</h6>
              </article>
              <article className="d-flex flex-column justify-content-between align-items-end">
                <article>{""}</article>
                <span className="price-product-detail">
                  ${each.quantity * each.productData.price}
                </span>
              </article>
            </article>
          </article>
        </article>
      ))}
    </>
  );
};

export default ConfirmCart;
