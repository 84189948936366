import React from "react";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <article style={{ backgroundColor: "#efefef", width: "100%" }}>
      <Helmet>
        <title>Fashion Zone | Privacy Policy</title>
      </Helmet>
      <div className="Terms-and-condition-main-container d-flex flex-column mt-5">
        <div className="single-title-with-paragraph d-flex flex-column ">
          <h4 className="d-flex gap-2 p-classname-title ">
            Privacy
            <span className="span-class-name-main" style={{ color: "#ff6016" }}>
              Policy
            </span>
          </h4>
          <span className="text-muted span-for-date-updated">
            Last Updated March 3rd 2024{" "}
          </span>
          <hr />

          <div className="single-paragraph-terms-conditions">
            <h3>1. Information We Collect</h3>
            <div className="text-muted">
              <p>We may collect personal information such as:</p>
              <ul>
                <li>
                  <p>Name</p>
                </li>
                <li>
                  <p>Contact Information (email address, phone number)</p>
                </li>
                <li>
                  <p>Your location(s)</p>
                </li>
              </ul>
            </div>
          </div>

          <div className="single-paragraph-terms-conditions">
            <h3>2. How We Use Your Information</h3>
            <div className="text-muted">
              <p>
                We use the collected information for the following purposes:
              </p>
              <ul>
                <li>
                  <p>Processing and fulfilling orders</p>
                </li>
                <li>
                  <p>Improving our products and services</p>
                </li>
                <li>
                  <p>
                    Communicating with you about promotions, updates, and orders
                  </p>
                </li>
                <li>
                  <p>Analyzing website performance and user behavior</p>
                </li>
              </ul>
            </div>
          </div>

          <div className="single-paragraph-terms-conditions">
            <h3>3. Sharing Your Information</h3>
            <div className="text-muted">
              <p> We may share your information with:</p>
              <ul>
                <li>
                  <p>
                    Service providers for order processing, shipping, and
                    payment processing
                  </p>
                </li>
                <li>
                  {" "}
                  <p>Legal authorities when required by law</p>
                </li>
              </ul>
              <p>
                We do not sell, rent, or trade your personal information to
                third parties for marketing purposes.
              </p>
            </div>
          </div>

          <div className="single-paragraph-terms-conditions">
            <h3>4. Your Choices</h3>
            <div className="text-muted">
              <h5>4.1 Opting Out</h5>
              <p>
                You may opt-out of receiving promotional communications by
                following the instructions provided in our emails or contacting
                us directly.
              </p>
              <h5>4.2 Access and Correction</h5>
              <p>
                You have the right to access and correct your personal
                information. Contact us to update your information.
              </p>
            </div>
          </div>

          <div className="single-paragraph-terms-conditions">
            <h3>5. Security</h3>
            <p className="text-muted">
              We implement reasonable security measures to protect your
              information. However, no method of transmission over the internet
              or electronic storage is entirely secure.
            </p>
          </div>

          <div className="single-paragraph-terms-conditions">
            <h3>6. Cookies</h3>
            <p className="text-muted">
              We use cookies to enhance your browsing experience. You can
              disable cookies in your browser settings, but this may affect the
              functionality of the website.
            </p>
          </div>

          <div className="single-paragraph-terms-conditions">
            <h3>7. Changes to Privacy Policy</h3>
            <p className="text-muted">
              We reserve the right to update this Privacy Policy. Changes will
              be posted on this page with an updated effective date.
            </p>
          </div>

          <div className="single-paragraph-terms-conditions">
            <h3>8. Contact Us</h3>
            <p className="text-muted">
              If you have questions about our Privacy Policy, please contact us
              at <b>info.fashionzone.lb@gmail.com</b>.
            </p>
          </div>
        </div>
      </div>
    </article>
  );
};

export default PrivacyPolicy;
