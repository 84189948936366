import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryById } from "../redux/apiCall/categoryCall";
import { getProductsBySubcategoryId } from "../redux/apiCall/productCall";
import { getAllSubCategoriesByCategoryId } from "../redux/apiCall/subcategoryCall";
import { useParams } from "react-router-dom";
import { productActions } from "../redux/slice/productSlice";
import ProductCard from "../components/ProductCard";
import Loader from "../Admin/components/Loader";
import "./styles/products.css";
import { toast } from "react-toastify";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { Helmet } from "react-helmet";

const ProductsPage = () => {
  const dispatch = useDispatch();
  const { categoryId } = useParams();

  //global states
  const { categories } = useSelector((state) => state.category);
  const { category } = useSelector((state) => state.category);
  const subcategories = useSelector(
    (state) => state.subcategory.subcategoriesByCategoryId
  );
  const { products } = useSelector((state) => state.product);
  const { productsBySubcategory } = useSelector((state) => state.product);

  //local states
  const [activeSubcategoryId, setActiveSubcategoryId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [loading, setLoading] = useState(false);
  const itemsPerPage = 8;
  useEffect(() => {
    dispatch(getCategoryById(categoryId));
    dispatch(getAllSubCategoriesByCategoryId(categoryId));
  }, [dispatch, categoryId]);

  useEffect(() => {
    if (subcategories.length > 0) {
      setActiveSubcategoryId(subcategories[0]._id);

      dispatch(getProductsBySubcategoryId(subcategories[0]._id)).then(
        (data) => {
          dispatch(productActions.setProducts(data));
        }
      );
      // console.log("subcategories at index 0, ", subcategories[0]);
    }
  }, [dispatch, subcategories]);

  const handleSubcategoryClick = (subcategoryId) => {
    try {
      setActiveSubcategoryId(subcategoryId);
      setLoading(true);
      dispatch(getProductsBySubcategoryId(subcategoryId)).then((data) => {
        dispatch(productActions.setProducts(data));
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = products?.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <article className="products-main-container-page">
        <Helmet>
          <title>{`Fashion Zone | ${category?.name}`}</title>
        </Helmet>
        <article className="cat-sub-products-main-article">
          <article
            className="category-name-in-product-page"
            style={{ cursor: "default" }}
          >
            <h1>
              <span style={{ color: "#ff6016" }}>{category.name}</span>{" "}
              <span style={{ color: "#fff" }}> Category</span>
            </h1>
          </article>

          <article>
            <ul
              style={{
                color: "#fff",
                listStyleType: "none",
                display: "flex",
                justifyContent: "center",
                gap: "50px",
                fontSize: "18px",
                marginBottom: "40px",
                padding: 0,
                flexWrap: "wrap",
              }}
            >
              {subcategories.map((subcategory) => (
                <li
                  key={subcategory._id}
                  onClick={() => handleSubcategoryClick(subcategory._id)}
                  style={{
                    color:
                      subcategory._id === activeSubcategoryId
                        ? "#ff6016"
                        : "white",
                    cursor: "pointer",
                  }}
                  className={
                    subcategory._id === activeSubcategoryId
                      ? "products-active"
                      : ""
                  }
                >
                  {subcategory.name}
                </li>
              ))}
            </ul>
          </article>

          {/* Conditional rendering of loader */}
          <article className="d-flex justify-content-center">
            {loading && <Loader />}
          </article>

          <article
            className="d-flex flex-row products-card-container"
            style={{
              justifyContent: "space-evenly",
              flexWrap: "wrap",
              gap: "20px",
              margin: "auto",
            }}
          >
            {currentItems?.length > 0
              ? currentItems.map((product) => (
                  // console.log("quantity, ", product.totalQuantity),
                  <ProductCard
                    key={product._id}
                    product={product.name}
                    price={product.price}
                    images={product.images}
                    productId={product._id}
                    productQuantity={product.totalQuantity}
                  />
                ))
              : !loading && <p>No products are found!</p>}
          </article>
          <div
            className="pagination justify-content-center"
            style={{ gap: "30px" }}
          >
            {products?.length > 8 && (
              <>
                <button
                  className="btn"
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  style={{
                    border: "0.1px solid #ff6016",
                    borderRadius: "5px",
                    backgroundColor: "black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaArrowLeftLong style={{ color: "#ff6016" }} />
                </button>
                <button
                  className="btn"
                  onClick={() => paginate(currentPage + 1)}
                  disabled={
                    currentPage === Math.ceil(products?.length / itemsPerPage)
                  }
                  style={{
                    border: "0.1px solid #ff6016",
                    borderRadius: "5px",
                    backgroundColor: "black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaArrowRightLong style={{ color: "#ff6016" }} />
                </button>
              </>
            )}
          </div>
        </article>
      </article>
    </>
  );
};

export default ProductsPage;
