import axios from "axios";
import { productActions } from "../slice/productSlice";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

export const getAllProduct = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "https://67.223.117.107/api/product",
        // "https://67.223.117.107/api/product",
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("userToken")}`,
          },
        }
      );
      const filteredProducts = data.data.filter(
        (product) => !product.isDeleted
      );

      if (filteredProducts.length === 0) {
        toast.info("No products available.");
      }
      const recommendedProducts = data.data.filter(
        (product) => product.isRecommended
      );

      dispatch(productActions.setProducts(filteredProducts));
      dispatch(productActions.setProductsCount(filteredProducts.length));
      dispatch(
        productActions.setRecommendedProductsCount(recommendedProducts.length)
      );
    } catch (error) {
      toast.error("Bad connection");
    }
  };
};

export const getProduct = (productId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        // `https://67.223.117.107/api/product/${productId}`
        `https://67.223.117.107/api/product/${productId}`
      );
      if (data.data.isDeleted) {
        toast.error("Product not found.");
        return;
      }
      // console.log("id", data.data);
      dispatch(productActions.setProduct(data.data));
      return data.data;
    } catch (error) {
      toast.error("Bad connection");
    }
  };
};

//delete product
export const deleteProduct = (productId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(
        `https://67.223.117.107/api/product/delete/${productId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("userToken")}`,
          },
        }
      );
      dispatch(productActions.deleteProducts(data.productId));
      toast.success("Product deleted successfully!");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

//edit product
export const editProduct = (productId, formData) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(
        `https://67.223.117.107/api/product/${productId}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("userToken")}`,
          },
        }
      );

      // console.log("results: ", data);
      toast.success("Product edited successfully.");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const createProduct = (newProduct) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "https://67.223.117.107/api/product/",
        newProduct,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("userToken")}`,
          },
        }
      );
      // console.log("Product created successfully:", data);
      dispatch(productActions.createProduct(data.data));
      toast.success(`Product ${data.data.name} created successfully.`);
    } catch (error) {
      console.error("Error creating product:", error.message);
      toast.error("An error occurred while creating the product.");
    }
  };
};

export const getProductsBySubcategoryId = (subcategoryId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `https://67.223.117.107/api/product/products/subcategory/${subcategoryId}`
      );
      // console.log(data.data);
      dispatch(productActions.setProducts(data.data));
      return data.data;
    } catch (error) {
      console.log(error);
    }
  };
};

export const toggleProductRecommendation = (productId, isRecommended) => {
  return async () => {
    try {
      const response = await axios.put(
        `https://67.223.117.107/api/product/toggle-recommendation/${productId}`,
        { isRecommended },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("userToken")}`,
          },
        }
      );
      // console.log("Recommendation toggled successfully", response);
    } catch (error) {
      console.log(error);
      toast.error("Error toggling recommendation!");
    }
  };
};

export const fetchSizeDetails = (productId, size) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `https://67.223.117.107/api/product/${productId}/size/${size}`
      );
      // console.log("size details: ", data.data);

      // Dispatch the action to update the Redux store
      dispatch(productActions.setSizeDetails(data.data));
    } catch (error) {
      toast.error("Error fetching size details.");
    }
  };
};

export const getRecommendedProducts = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `https://67.223.117.107/api/product/products/nothidden/recommended`
      );
      // console.log(data.data);
      dispatch(productActions.setRecommendedCarousel(data.data));
      return data.data;
    } catch (error) {
      console.log(error);
    }
  };
};

export const getAllNonHiddenProducts = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `https://67.223.117.107/api/product/products/nothidden`
      );
      // console.log(data.data);
      dispatch(productActions.setAllProducts(data.data));
      return data.data;
    } catch (error) {
      console.log(error);
    }
  };
};
