import React from "react";
import "./styles/AdminLogin.css";
import AdminLoginLogo from "../../Assets/AdminLoginLogo.svg";
import { AdminAuthLogin } from "../../redux/apiCall/authAdminCall";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FashionZoneLogo from "../../Assets/logooo.png";

const AdminLogin = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    dispatch(AdminAuthLogin({ username, password }))
      .then((data) => {
        // console.log(data, "dataa");
        if (data) {
          navigate("/admin/main");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <article className="div-admin-dashboard-main article-admin-login-main-container d-flex">
        <img
          src={FashionZoneLogo}
          alt="fashion zone logo"
          className="fashionzone-logo-login-page"
        />
        <article className="article-admin-login-main-form d-flex flex-column align-items-center">
          <img
            src={AdminLoginLogo}
            alt="Admin Login Logo"
            className="img-AdminLoginLogo-form"
          />
          <h4 className="mt-4">Login to your Account</h4>

          <form className="d-flex flex-row flex-wrap justify-content-center">
            <article className="article-admin-login-main-form-container d-flex">
              <label
                htmlFor="username"
                className="article-admin-login-main-form-label"
              >
                Username
              </label>
              <input
                id="username"
                className="article-admin-login-main-form-input"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                placeholder="Enter your username"
                type="text"
                required
              />
            </article>

            <article className="article-admin-login-main-form-container d-flex">
              <label
                htmlFor="password"
                className="article-admin-login-main-form-label"
              >
                Password&nbsp;
              </label>
              <input
                id="password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                placeholder="Enter your password"
                required
                className="article-admin-login-main-form-input"
              />
            </article>
          </form>
          <button
            type="button"
            onClick={handleLogin}
            className="admin-login-button"
            style={{ marginTop: "40px" }}
          >
            Login
          </button>
        </article>
      </article>
    </>
  );
};

export default AdminLogin;
