import Card from "react-bootstrap/Card";
import { useSelector } from "react-redux";

const CategoriesCard = () => {
  const { categoryCount } = useSelector((state) => state.category);
  return (
    <div className="d-flex  mt-5 gap-5">
      <div className="d-flex flex-column">
        <span className="text-muted">Categories</span>
        <Card
          className="d-flex flex-row justify-content-between align-items-center"
          style={{ width: 210, height: 80 }}
        >
          <Card
            className="bg-dark  text-warning-color-main"
            style={{ width: 80, height: 80 }}
          >
            <Card.Body className="rounded">
              <Card.Title className="text-center display-6">
                {/* <FaUsers style={{ width: 40, height: 40, color: "#ff6016" }} /> */}
                <i
                  className="fa fa-th"
                  aria-hidden="true"
                  style={{ width: 40, height: 40, color: "#ff6016" }}
                ></i>
              </Card.Title>
            </Card.Body>
          </Card>
          <div>
            <Card.Title className="text-center p-3  display-5 text-dark">
              {categoryCount}
            </Card.Title>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default CategoriesCard;
