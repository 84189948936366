import React from "react";
import { Route, Routes } from "react-router-dom";

//admin pages
import AdminProducts from "../Admin/pages/AdminProducts";
import AdminLogin from "../Admin/pages/AdminLogin";
import AdminUsers from "../Admin/pages/AdminUsers";
import AdminCategories from "../Admin/pages/AdminCategories";
import AdminEditCategory from "../Admin/pages/AdminEditCategory";
import AdminAddCategory from "../Admin/pages/AdminAddCategory";
import AdminSubcategories from "../Admin/pages/AdminSubcategories";
import AdminEditSubcategory from "../Admin/pages/AdminEditSubcategory";
import AdminAddSubcategory from "../Admin/pages/AdminAddSubcategory";
import AdminOrders from "../Admin/pages/AdminOrders";
import AdminAddProduct from "../Admin/pages/AdminAddProduct";
import AdminEditProduct from "../Admin/pages/AdminEditProduct";

//client
import Home from "../pages/Home";
import Contact from "../pages/Contact";
import About from "../pages/About";
import Products from "../pages/Products";
import LoginPage from "../pages/LoginPage";
import SignupPage from "../pages/SignupPage";
import UserProfile from "../pages/UserProfile";
import NotFoundPage from "../pages/NotFoundPage";
import ManageLocation from "../pages/ManageLocation";
import OrderHistory from "../pages/OrderHistory";
import SingleProduct from "../pages/SingleProduct";

//Protected Routes
import UserProtectedRoute from "./userProtectedRoutes.jsx";
import AdminProtectedRoute from "./adminProtectedRoutes.jsx";
import TermsAndConditions from "../pages/Terms&Conditions.jsx";
import PrivacyPolicy from "../pages/PrivacyPolicy.jsx";
import AllProducts from "../pages/AllProducts.jsx";

const AppRoutes = () => {
  return (
    <Routes>
      {/* client */}
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<About />} />
      {/* <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<SignupPage />} /> */}

      {/* <Route element={<UserProtectedRoute />}>
        <Route path="/profile" element={<UserProfile />} />
      </Route>

      <Route element={<UserProtectedRoute />}>
        <Route path="/manage-location" element={<ManageLocation />} />
      </Route>

      <Route element={<UserProtectedRoute />}>
        <Route path="/myorders" element={<OrderHistory />} />
      </Route> */}

      <Route path="/product/:productId" element={<SingleProduct />} />
      <Route path="/products/:categoryId" element={<Products />} />
      <Route path="/terms&conditions" element={<TermsAndConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/allproducts" element={<AllProducts />} />

      {/* admin */}
      <Route path="/admin/login" element={<AdminLogin />} />

      <Route element={<AdminProtectedRoute />}>
        <Route path="/admin/main" element={<AdminProducts />} />
      </Route>

      <Route element={<AdminProtectedRoute />}>
        <Route path="/admin/product/edit" element={<AdminEditProduct />} />
      </Route>

      <Route element={<AdminProtectedRoute />}>
        <Route path="/admin/product/add" element={<AdminAddProduct />} />
      </Route>

      {/* <Route element={<AdminProtectedRoute />}>
        <Route path="/admin/users" element={<AdminUsers />} />
      </Route> */}

      <Route element={<AdminProtectedRoute />}>
        <Route path="/admin/categories" element={<AdminCategories />} />
      </Route>

      <Route element={<AdminProtectedRoute />}>
        <Route path="/admin/category/edit" element={<AdminEditCategory />} />
      </Route>

      <Route element={<AdminProtectedRoute />}>
        <Route path="/admin/category/add" element={<AdminAddCategory />} />
      </Route>

      <Route element={<AdminProtectedRoute />}>
        <Route path="/admin/subcategories" element={<AdminSubcategories />} />
      </Route>

      <Route element={<AdminProtectedRoute />}>
        <Route
          path="/admin/subcategory/edit"
          element={<AdminEditSubcategory />}
        />
      </Route>

      <Route element={<AdminProtectedRoute />}>
        <Route
          path="/admin/subcategory/add"
          element={<AdminAddSubcategory />}
        />
      </Route>

      <Route element={<AdminProtectedRoute />}>
        <Route path="admin/orders" element={<AdminOrders />} />
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
