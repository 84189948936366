import React, { useEffect } from "react";
import "./styles/admin.css";
import Sidebar from "../components/Sidebar";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import {
  getAllSubcategories,
  toggleSubcategoryVisibility,
  deleteSubcategory,
} from "../../redux/apiCall/subcategoryCall";
import { Link } from "react-router-dom";
import SubcategoriesCard from "../components/SubcategoriesCard";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const AdminSubcategories = () => {
  const dispatch = useDispatch();
  const { subcategories } = useSelector((state) => state.subcategory);

  // console.log("Admin User Page", subcategories);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllSubcategories());
    };

    fetchData();
  }, [dispatch]);

  const handleToggleVisibility = async (id, isHidden) => {
    await dispatch(toggleSubcategoryVisibility(id, !isHidden));
    await dispatch(getAllSubcategories());
  };

  const handleDelete = async (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Subcategory!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await dispatch(deleteSubcategory(id));
        dispatch(getAllSubcategories());
      }
    });
  };

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "Subcategory Name",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "isHidden",
      headerName: "Status",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            onClick={() =>
              handleToggleVisibility(params.row._id, params.row.isHidden)
            }
          >
            {params.row.isHidden ? (
              <VisibilityOff style={{ color: "#ff6016" }} />
            ) : (
              <Visibility />
            )}
          </IconButton>
        </div>
      ),
    },
    {
      field: "categoryName",
      headerName: "Category Name",
      width: 200,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.row.categoryId.name, // Extract categoryName from categoryId
    },

    {
      field: "Action",
      headerName: "Action",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 200,
      headerAlign: "center",
      renderCell: (params) => (
        <div
          style={{
            marginLeft: "25px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Link
            to="/admin/subcategory/edit"
            state={{
              subcategoryId: params.row.id,
              subcategoryName: params.row.name,
            }}
          >
            <button
              className="btn btn-primary btn-sm"
              style={{ marginRight: "25px", borderRadius: "5px" }}
            >
              Edit
            </button>
          </Link>

          <button
            className="btn btn-danger btn-sm"
            style={{ borderRadius: "5px" }}
            onClick={() => handleDelete(params.row._id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const rows = subcategories.map((subcategory) => ({
    ...subcategory,
    id: subcategory._id,
  }));

  return (
    <article className="d-flex div-admin-dashboard-main">
      <Sidebar />
      <article className="d-flex flex-column div-admin-dashboard-main-child">
        <SubcategoriesCard />
        <article className="d-flex justify-content-end">
          <Link to="/admin/subcategory/add">
            <button className="admin-add-above-table-button">
              Add Subcategory
            </button>
          </Link>
        </article>

        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={6}
            rowHeight={50}
            style={{ display: "flex", justifyContent: "center" }}
          />
        </div>
      </article>
    </article>
  );
};

export default AdminSubcategories;
