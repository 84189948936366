import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductCard from "../components/ProductCard";
import Loader from "../Admin/components/Loader";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { getAllNonHiddenProducts } from "../redux/apiCall/productCall";
import { Helmet } from "react-helmet";

const AllProducts = () => {
  const dispatch = useDispatch();
  const { allProducts } = useSelector((state) => state.product);
  // console.log("first, ", allProducts);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 12;

  useEffect(() => {
    dispatch(getAllNonHiddenProducts());
  }, [dispatch]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = allProducts?.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <article className="products-main-container-page">
        <Helmet>
          <title>Fashion Zone | All Products</title>
        </Helmet>
        <article className="cat-sub-products-main-article">
          <article className="category-name-in-product-page">
            <h1>
              <span style={{ color: "#ff6016" }}>All</span>{" "}
              <span style={{ color: "#fff" }}> Products</span>
            </h1>
          </article>

          {/* Conditional rendering of loader */}
          <article className="d-flex justify-content-center">
            {loading && <Loader />}
          </article>

          <article
            className="d-flex flex-row products-card-container"
            style={{
              justifyContent: "space-evenly",
              flexWrap: "wrap",
              gap: "20px",
              margin: "auto",
            }}
          >
            {currentItems?.length > 0
              ? currentItems.map((product) => (
                  <ProductCard
                    key={product._id}
                    product={product.name}
                    price={product.price}
                    images={product.images}
                    productId={product._id}
                    productQuantity={product.totalQuantity}
                  />
                ))
              : !loading && <p>No products are found!</p>}
          </article>
          <div
            className="pagination justify-content-center"
            style={{ gap: "30px" }}
          >
            {allProducts?.length > 11 && (
              <>
                <button
                  className="btn"
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  style={{
                    border: "0.1px solid #ff6016",
                    borderRadius: "5px",
                    backgroundColor: "black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaArrowLeftLong style={{ color: "#ff6016" }} />
                </button>
                <button
                  className="btn"
                  onClick={() => paginate(currentPage + 1)}
                  disabled={
                    currentPage ===
                    Math.ceil(allProducts?.length / itemsPerPage)
                  }
                  style={{
                    border: "0.1px solid #ff6016",
                    borderRadius: "5px",
                    backgroundColor: "black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaArrowRightLong style={{ color: "#ff6016" }} />
                </button>
              </>
            )}
          </div>
        </article>
      </article>
    </>
  );
};

export default AllProducts;
