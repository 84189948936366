import React from "react";
import FashionZoneLogo from "../../Assets/logooo.png";
import "./styles/sidebar.css";
import { useDispatch } from "react-redux";
import { logoutAdmin } from "../../redux/apiCall/authAdminCall";
import {
  CDBSidebar,
  CDBSidebarHeader,
  CDBSidebarMenuItem,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarFooter,
} from "cdbreact";
import { useNavigate, NavLink, Link } from "react-router-dom";
import { toast } from "react-toastify";

const divStyle = {
  fontSize: "22px",
  marginTop: "100px",
};

const Sidebar = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logoutAdmin());
    toast.success("Logged out successfuly!");
    navigate("/admin/login");
  };

  return (
    <article
      className="sidebar-component-main"
      style={{ display: "flex", overflow: "scroll initial" }}
    >
      <CDBSidebar textColor="#fff" backgroundColor="#1A1C1E">
        <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>
          <Link exact="true" to={"/admin"}>
            {" "}
            <img
              alt="Fashion Zone Logo"
              src={FashionZoneLogo}
              style={{ width: "50px", marginLeft: "70px" }}
            />
          </Link>
        </CDBSidebarHeader>

        <CDBSidebarContent>
          <CDBSidebarMenu>
            <NavLink
              exact="true"
              to="/admin/main"
              activeclassname="activeClicked"
              end
            >
              <CDBSidebarMenuItem icon="home" iconSize="lg">
                <span style={divStyle}>Main</span>
              </CDBSidebarMenuItem>
            </NavLink>

            <NavLink
              exact="true"
              to="/admin/orders"
              activeclassname="activeClicked"
              end
            >
              <CDBSidebarMenuItem icon="list-alt" iconSize="lg">
                <span style={divStyle}>Orders</span>
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact="true"
              to="/admin/categories"
              activeclassname="activeClicked"
              end
            >
              <CDBSidebarMenuItem
                icon="th"
                iconType="solid"
                textFontSize="16px"
                iconSize="lg"
              >
                <span style={divStyle}>Categories</span>
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact="true"
              to="/admin/subcategories"
              activeclassname="activeClicked"
              end
            >
              <CDBSidebarMenuItem
                icon="boxes"
                iconType="solid"
                textFontSize="16px"
                iconSize="lg"
              >
                <span style={divStyle}>Subcategories</span>
              </CDBSidebarMenuItem>
            </NavLink>

            {/* <NavLink
              exact="true"
              to="/admin/users"
              activeclassname="activeClicked"
              end
            >
              <CDBSidebarMenuItem
                icon="fa fa-users"
                iconType="solid"
                textFontSize="16px"
                iconSize="lg"
              >
                <span style={divStyle}>Users</span>
              </CDBSidebarMenuItem>
            </NavLink> */}

            <CDBSidebarMenuItem
              icon="sign-out-alt"
              iconType="solid"
              textFontSize="16px"
              iconSize="lg"
              onClick={handleLogout}
            >
              <span style={divStyle}>Logout</span>
            </CDBSidebarMenuItem>
          </CDBSidebarMenu>
        </CDBSidebarContent>
        <CDBSidebarFooter style={{ textAlign: "center" }}>
          <article
            className="sidebar-btn-wrapper"
            style={{ padding: "20px 5px" }}
          >
            Crafted by CODEX
          </article>
        </CDBSidebarFooter>
      </CDBSidebar>
    </article>
  );
};

export default Sidebar;
