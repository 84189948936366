import React from "react";
import "./styles/about.css";
import Shop from "../Assets/FashionZone-Shop.jpg";
import Shop1 from "../Assets/2023-09-21.jpg";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <article className="about-us-page-main-component">
      <Helmet>
        <title>Fashion Zone | About Us</title>
      </Helmet>
      <article className="d-flex flex-column about-us-page-main-content">
        <article className="d-flex flex-row flex-wrap about-us-page-main-content-row">
          <article className="d-flex flex-column about-us-page-main-content-title-text">
            <article className="about-us-page-main-content-title">
              <h1>
                Welcome to&nbsp;
                <span style={{ color: "#ff6016" }}>Fashion Zone</span>
              </h1>
            </article>
            <article className="container about-us-page-main-content-row-text">
              At Fashion Zone, we're not just about selling clothes; we're about
              curating a lifestyle. We believe that fashion goes beyond mere
              clothing choices; it's a reflection of your unique lifestyle,
              personality, and aspirations. That's why we've made it our mission
              to provide teens and young adults with the ultimate destination
              for trendy sportswear and casual fashion essentials that speak to
              who you are and what you love.
            </article>
          </article>

          <article className="about-us-page-main-content-row-image">
            <img className="container" src={Shop} alt="fashion zone shop" />
          </article>
        </article>

        <article className="d-flex flex-row-reverse flex-wrap about-us-page-main-content-row">
          <article className="d-flex flex-column about-us-page-main-content-title-text">
            <article className="about-us-page-main-content-title">
              <h1>
                Quality, Comfort,&nbsp;
                <span style={{ color: "#ff6016" }}>and Style</span>
              </h1>
            </article>
            <article className="container about-us-page-main-content-row-text">
              Our Promise to You We understand that looking good is just as
              important as feeling good, which is why we place a strong emphasis
              on quality, comfort, and style in everything we offer. Each piece
              in our collection is crafted with the utmost attention to detail
              and designed to not only look great but also feel amazing to wear.
              Whether you're hitting the gym, hanging out with friends, or
              exploring the city, you can trust Fashion Zone to provide you with
              the perfect blend of comfort, style, and functionality.
            </article>
          </article>

          <article className="about-us-page-main-content-row-image">
            <img className="container" src={Shop1} alt="fashion zone shop" />
          </article>
        </article>
      </article>
    </article>
  );
};

export default About;
