import React, { useEffect } from "react";
import "./styles/admin.css";
import Sidebar from "../components/Sidebar";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import {
  getAllCategories,
  deleteCategory,
} from "../../redux/apiCall/categoryCall";
import { Link } from "react-router-dom";
import CategoriesCard from "../components/CategoriesCard";

const AdminCategories = () => {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.category);

  // console.log("Admin User Page", categories);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllCategories());
    };

    fetchData();
  }, [dispatch]);

  const handleDelete = async (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Category!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await dispatch(deleteCategory(id));
        dispatch(getAllCategories());
      }
    });
  };

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "Category Name",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "image",
      headerName: "Image",
      width: 100,
      headerAlign: "center",
      renderCell: (params) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={params.row.image}
            width={100}
            height={100}
            alt={params.row.name}
            style={{ margin: "auto" }}
          />
        </div>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 200,
      headerAlign: "center",
      renderCell: (params) => (
        <div
          style={{
            marginLeft: "25px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Link
            to="/admin/category/edit"
            state={{ categoryId: params.row.id, categoryName: params.row.name }}
          >
            <button
              className="btn btn-primary btn-sm"
              style={{ marginRight: "25px", borderRadius: "5px" }}
            >
              Edit
            </button>
          </Link>

          <button
            className="btn btn-danger btn-sm"
            style={{ borderRadius: "5px" }}
            onClick={() => handleDelete(params.row._id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const rows = categories.map((category) => ({
    ...category,
    id: category._id,
  }));

  return (
    <article className="d-flex div-admin-dashboard-main">
      <Sidebar />
      <article className="d-flex flex-column div-admin-dashboard-main-child">
        <CategoriesCard />
        <article className="d-flex justify-content-end">
          <Link to="/admin/category/add">
            <button className="admin-add-above-table-button">
              Add Category
            </button>
          </Link>
        </article>

        <div style={{ height: 400, width: "100%" }}>
          <div className="d-flex justify-content-end "></div>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={6}
            rowHeight={100}
            style={{ display: "flex", justifyContent: "center" }}
          />
        </div>
      </article>
    </article>
  );
};

export default AdminCategories;
