import { createSlice } from "@reduxjs/toolkit";

const initialState = JSON.parse(localStorage.getItem("saved-order")) || [];
const initialTotalPrice = sessionStorage.getItem("total") || 0;

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    carts: JSON.parse(sessionStorage.getItem("cart")) || [],
    totalPrice: initialTotalPrice || 0,
  },
  reducers: {
    setCarts(state, action) {
      state.carts = action.payload;
      var total = 0;
      state.carts.map(
        (each) => (total += each.quantity * each.productData.price)
      );
      state.totalPrice = total;
      sessionStorage.setItem("total", total);
    },
    deleteCarts(state, action) {
      var productFound = -1;

      if (state.carts.length > 0) {
        productFound = state.carts.findIndex(
          (p) =>
            p.productId === action.payload.productId &&
            p.chosenColor === action.payload.chosenColor &&
            p.chosenSize === action.payload.chosenSize
        );
      }
      if (productFound !== -1) {
        var newArray = state.carts.filter(
          (item, index) => index !== productFound
        );
        state.carts = newArray;
      }
      var total = 0;
      state.carts.map(
        (each) => (total += each.quantity * each.productData.price)
      );
      state.totalPrice = total;
      sessionStorage.setItem("cart", JSON.stringify(state.carts));
    },
    setTotal(state, action) {
      state.totalPrice = action.payload;
    },
    emptyCarts(state, action) {
      state.carts = [];
      state.totalPrice = 0;
    },
  },
});

const cartReducer = cartSlice.reducer;
const cartActions = cartSlice.actions;

export { cartActions, cartReducer };
