import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editProduct, getProduct } from "../../redux/apiCall/productCall";
import { useNavigate, useLocation } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Loader from "../components/Loader";
import Offcanvas from "react-bootstrap/Offcanvas";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";

const AdminEditProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { productId } = location.state;

  // Initialize state with product data from Redux store
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [show, setShow] = useState(false);
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [size, setSize] = useState("");
  const [color, setPColor] = useState("");
  const [quantity, setQuantity] = useState("");

  useEffect(() => {
    dispatch(getProduct(productId)).then((product) => {
      setName(product.name);
      setDescription(product.description);
      setPrice(product.price);
    });
  }, [dispatch, productId]); // Depend on the product ID to refetch if it changes

  //function to edit name , description and price
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = {
        name:name,
        description:description,
        price:price,
      };
      dispatch(editProduct(productId, formData))
      setLoading(false)
      navigate('/admin/main')
    } catch (error) {
      setLoading(false)
      console.log('errorr', error)
      toast.error('ERROR')
    }
  };

  //function to edit quantity by size and color
  const handleQuantityChange = (e)=>{
    e.preventDefault()
    try {
      const formData = {
        size:size,
        color:color,
        quantity:quantity,
      };
      dispatch(editProduct(productId, formData))
      setLoading(false)
      setSize('')
      setPColor('')
      setQuantity('')
    } catch (error) {
      setLoading(false)
      console.log('errorr', error)
      toast.error('ERROR')
    }
  }
  //offcanvas controllers
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <article className="d-flex div-admin-dashboard-main">
      <Sidebar />

      <article className="d-flex flex-column" style={{ marginTop: "20px" }}>
        <article>
          <h2 style={{ fontWeight: "bold" }}>
            <span style={{ color: "#ff6016" }}>Edit</span> Product
          </h2>
        </article>

        <article style={{ width: "600px", marginTop: "20px" }}>
          <form className="w-50">
            <article className="mb-3">
              <label htmlFor="name" className="form-label">
                <h5>Product Name</h5>
              </label>
              <input
                className="form-control"
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </article>

            <article className="mb-3">
              <label htmlFor="description" className="form-label">
                <h5>Description</h5>
              </label>
              <textarea
                className="form-control"
                id="description"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </article>

            <article className="mb-3">
              <label htmlFor="price" className="form-label">
                <h5>Price</h5>
              </label>
              <input
                className="form-control"
                type="number"
                id="price"
                name="price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </article>

            <button
              className="btn btn-warning text-dark"
              type="reset"
              onClick={handleShow}
            >
              Change Quantity By Size and Color
            </button>

            <button
              type="submit"
              className="admin-login-button d-flex align-items-center justify-content-center"
              style={{ marginTop: "20px" }}
              onClick={handleSubmit}
              // disabled={loading}
            >
              {loading ? <Loader /> : "Save Changes"}
            </button>
          </form>
        </article>
      </article>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Quantity</Modal.Title>
        </Modal.Header>

        <Modal.Body className="d-flex flex-column" style={{ gap: "20px" }}>
          <form>
            <label htmlFor="size" className="form-label">
              <h5>Size</h5>
            </label>
            <input
              className="form-control"
              type="text"
              id="size"
              name="size"
              value={size}
              onChange={(e) => setSize(e.target.value)}
            />
            <label htmlFor="color" className="form-label mt-3">
              <h5>Color</h5>
            </label>
            <input
              className="form-control"
              type="text"
              id="color"
              name="color"
              value={color}
              onChange={(e) => setPColor(e.target.value)}
            />

            <label htmlFor="quantity" className="form-label mt-3">
              <h5>Quantity</h5>
            </label>
            <input
              className="form-control"
              type="number"
              id="quantity"
              name="quantity"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </form>
        </Modal.Body>

        <Modal.Footer style={{ justifyContent: "space-between" }}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <button className="confirm-order-button" onClick={handleQuantityChange}>Save</button>
        </Modal.Footer>
      </Modal>
    </article>
  );
};

export default AdminEditProduct;
