import Card from "react-bootstrap/Card";
import { FaBoxes } from "react-icons/fa";
import { useSelector } from "react-redux";
import { VisibilityOff } from "@mui/icons-material";

const SubcategoriesCard = () => {
  const { subcategoryCount, hiddenSubcategoriesCount } = useSelector(
    (state) => state.subcategory
  );

  return (
    <article className="d-flex  mt-5 gap-5">
      <article className="d-flex flex-column">
        <span className="text-muted">Subcategories</span>
        <Card
          className="d-flex flex-row justify-content-between align-items-center"
          style={{ width: 210, height: 80 }}
        >
          <Card
            className="bg-dark  text-warning-color-main"
            style={{ width: 80, height: 80 }}
          >
            <Card.Body className="rounded">
              <Card.Title className="text-center display-6 d-flex justify-content-center">
                <FaBoxes style={{ width: 40, height: 40, color: "#ff6016" }} />
              </Card.Title>
            </Card.Body>
          </Card>
          <article>
            <Card.Title className="text-center p-3  display-5 text-dark">
              {subcategoryCount}
            </Card.Title>
          </article>
        </Card>
      </article>

      <article className="d-flex flex-column">
        <span className="text-muted">Hidden Subcategories</span>
        <Card
          className="d-flex flex-row justify-content-between align-items-center"
          style={{ width: 210, height: 80 }}
        >
          <Card
            className="bg-dark  text-warning-color-main"
            style={{ width: 80, height: 80 }}
          >
            <Card.Body className="rounded">
              <Card.Title className="text-center display-6 d-flex justify-content-center">
                <VisibilityOff
                  style={{ width: 40, height: 40, color: "#ff6016" }}
                />
              </Card.Title>
            </Card.Body>
          </Card>
          <article>
            <Card.Title className="text-center p-3  display-5 text-dark">
              {hiddenSubcategoriesCount}
            </Card.Title>
          </article>
        </Card>
      </article>
    </article>
  );
};

export default SubcategoriesCard;
