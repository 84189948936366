import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addCategory } from "../../redux/apiCall/categoryCall";
import { useNavigate } from "react-router-dom";

import Loader from "../components/Loader";
import Sidebar from "../components/Sidebar";

const AdminAddCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [categoryImage, setCategoryImage] = useState(null);

  const handleNameChange = (e) => {
    setCategoryName(e.target.value);
  };

  const handleImageChange = (e) => {
    setCategoryImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("name", categoryName);
      formData.append("image", categoryImage);

      await dispatch(addCategory(formData));
      navigate("/admin/categories");

      setCategoryName("");
      setCategoryImage(null);
      setLoading(false);
    } catch (error) {
      console.error("Error adding category:", error);
      setLoading(false);
    }
  };

  return (
    <article className="d-flex div-admin-dashboard-main">
      <Sidebar />
      <article className="d-flex flex-column" style={{ marginTop: "20px" }}>
        <article>
          <h2 style={{ fontWeight: "bold" }}>
            <span style={{ color: "#ff6016" }}>Add</span> Category
          </h2>
        </article>

        <article style={{ width: "600px", marginTop: "20px" }}>
          <form onSubmit={handleSubmit} className="w-50">
            <article className="mb-3">
              <label htmlFor="categoryName" className="form-label">
                <h5>Category Name</h5>
              </label>
              <input
                type="text"
                className="form-control"
                id="categoryName"
                name="name"
                value={categoryName}
                onChange={handleNameChange}
                required
              />
            </article>
            <article className="mb-3" style={{ marginTop: "20px" }}>
              <label htmlFor="categoryImage" className="form-label">
                <h5>Category Image</h5>
              </label>
              <input
                type="file"
                className="form-control"
                id="categoryImage"
                name="image"
                onChange={handleImageChange}
                required
              />
            </article>
            <button
              type="submit"
              className="admin-login-button"
              style={{ marginTop: "20px" }}
              disabled={loading}
            >
              {loading ? <Loader /> : "Save"}
            </button>
          </form>
        </article>
      </article>
    </article>
  );
};

export default AdminAddCategory;
