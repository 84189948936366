import axios from "axios";
import { toast } from "react-toastify";
import { categoryActions } from "../slice/categorySlice";
import Cookies from "js-cookie";

export const getAllCategories = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        "https://67.223.117.107/api/category"
      );
      // console.log("first", response.data.data);
      dispatch(categoryActions.setCategories(response.data.data));
      dispatch(categoryActions.setCategoriesCount(response.data.data.length));
    } catch (error) {
      console.log(error);
      toast.error("Bad connection!");
    }
  };
};

export const deleteCategory = (categoryId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(
        `https://67.223.117.107/api/category/delete/${categoryId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("userToken")}`,
          },
        }
      );
      // console.log("data", data);
      dispatch(categoryActions.deleteCategory(categoryId));
      toast.success("Category deleted successfully.");
    } catch (error) {
      toast.error("Please try again later.");
    }
  };
};

export const editCategory = (categoryId, formData) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(
        `https://67.223.117.107/api/category/${categoryId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("userToken")}`,
          },
        }
      );

      dispatch(categoryActions.editCategory(response.data));
      toast.success("Category edited successfully.");
    } catch (error) {
      console.error("Error editing category:", error);
      toast.error("Failed to edit category. Please try again later.");
      throw error;
    }
  };
};

export const addCategory = (categoryData) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        "https://67.223.117.107/api/category/",
        categoryData,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("userToken")}`,
          },
        }
      );
      dispatch(categoryActions.addCategory(response.data.data));
      toast.success("Category added successfully.");

      return response.data.data;
    } catch (error) {
      console.error("Error creating category:", error);
      throw error;
    }
  };
};

//api call to get category by its id

export const getCategoryById = (categoryId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `https://67.223.117.107/api/category/${categoryId}`
      );

      dispatch(categoryActions.setCategory(response.data.data));
      return response.data.data;
      // toast.success("Category found successfully.");
    } catch (error) {
      console.error("Error finding category:", error);
      toast.error("Failed to find category.");
      throw error;
    }
  };
};
