import React from "react";
import { Link } from "react-router-dom";
import "./styles/category.css";

const Category = ({ category, image, categoryId }) => {
  return (
    <Link to={`/products/${categoryId}`}>
      <article className="category-single-component-main">
        <article className="dark-overlay">
          <p className="overlay-text">{category}</p>
        </article>
        <img
          className="image-for-category-single"
          src={image}
          alt={category}
          style={{ objectFit: "contain" }}
        />
      </article>
    </Link>
  );
};

export default Category;
