import Card from "react-bootstrap/Card";
import "./styles/sidebar.css";

import { useSelector, useDispatch } from "react-redux";
import { MdOutlinePendingActions } from "react-icons/md";
import { FaRegCircleCheck } from "react-icons/fa6";
import { MdOutlineCancel } from "react-icons/md";
import { CiDeliveryTruck } from "react-icons/ci";
import { TbProgressCheck } from "react-icons/tb";

import { useEffect } from "react";
import {
  getAllOrders,
  getAccepted,
  getCanceled,
  getDelivered,
  getPending,
  getOnTheWay,
} from "../../redux/apiCall/orderCall";
const OrdersCard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllOrders());
    dispatch(getPending());
    dispatch(getAccepted());
    dispatch(getCanceled());
    dispatch(getDelivered());
    dispatch(getOnTheWay());
  }, [dispatch]);

  const { orderCount, pending, accepted, delivered, canceled, ontheway } =
    useSelector((state) => state.order);
  // console.log("ordersCard", pending);
  return (
    <div className="d-flex flex-column mt-5 gap-3">
      <article className="d-flex" style={{ justifyContent: "space-between" }}>
        <article className="d-flex flex-column">
          <span className="text-muted">All Orders</span>
          <Card
            className="d-flex flex-row justify-content-between align-items-center"
            style={{ width: 210, height: 80 }}
          >
            <Card
              className="bg-dark  text-warning-color-main"
              style={{ width: 80, height: 80 }}
            >
              <Card.Body className="rounded">
                <Card.Title className="text-center display-6 d-flex justify-content-center">
                  <i
                    className="fa fa-list"
                    aria-hidden="true"
                    style={{ width: 40, height: 40, color: "#ff6016" }}
                  ></i>
                </Card.Title>
              </Card.Body>
            </Card>
            <article>
              <Card.Title className="text-center p-3  display-5 text-dark">
                {orderCount}
              </Card.Title>
            </article>
          </Card>
        </article>

        <div className="d-flex flex-column">
          <span className="text-muted">Pending Orders</span>
          <Card
            className="d-flex flex-row justify-content-between align-items-center"
            style={{ width: 210, height: 80 }}
          >
            <Card
              className="bg-dark  text-warning-color-main"
              style={{ width: 80, height: 80 }}
            >
              <Card.Body className="rounded" style={{ color: "red" }}>
                <Card.Title className="text-center display-6 d-flex justify-content-center">
                  <MdOutlinePendingActions variant="text-white" />
                </Card.Title>
              </Card.Body>
            </Card>
            <div>
              <Card.Title
                className="text-center  p-3   display-5"
                style={{ color: "red" }}
              >
                {pending}
              </Card.Title>
            </div>
          </Card>
        </div>

        {/* accepted orders */}
        <div className="d-flex flex-column">
          <span className="text-muted">Confirmed Orders</span>
          <Card
            className="d-flex flex-row justify-content-between align-items-center"
            style={{ width: 210, height: 80 }}
          >
            <Card
              className="bg-dark  text-warning-color-main"
              style={{ width: 80, height: 80 }}
            >
              <Card.Body
                className="rounded"
                style={{ color: "rgb(9, 174, 0)" }}
              >
                <Card.Title className="text-center display-6 d-flex justify-content-center">
                  <FaRegCircleCheck />
                </Card.Title>
              </Card.Body>
            </Card>
            <div>
              <Card.Title
                className="text-center  p-3   display-5"
                style={{ color: "rgb(9, 174, 0)" }}
              >
                {accepted}
              </Card.Title>
            </div>
          </Card>
        </div>
      </article>

      <article className="d-flex" style={{ justifyContent: "space-between" }}>
        {/* On the way orders */}
        <div className="d-flex flex-column">
          <span className="text-muted">On The Way Orders</span>
          <Card
            className="d-flex flex-row justify-content-between align-items-center"
            style={{ width: 210, height: 80 }}
          >
            <Card
              className="bg-dark  text-warning-color-main"
              style={{ width: 80, height: 80 }}
            >
              <Card.Body className="rounded" style={{ color: "orange" }}>
                <Card.Title className="text-center display-6 d-flex justify-content-center">
                  <TbProgressCheck />
                </Card.Title>
              </Card.Body>
            </Card>
            <div>
              <Card.Title
                className="text-center  p-3   display-5"
                style={{ color: "orange" }}
              >
                {ontheway}
              </Card.Title>
            </div>
          </Card>
        </div>

        <div className="d-flex flex-column">
          <span className="text-muted">Delivered Orders</span>
          <Card
            className="d-flex flex-row justify-content-between align-items-center"
            style={{ width: 210, height: 80 }}
          >
            <Card
              className="bg-dark  text-warning-color-main"
              style={{ width: 80, height: 80 }}
            >
              <Card.Body className="rounded" style={{ color: "blue" }}>
                <Card.Title className="text-center display-6 d-flex justify-content-center">
                  <CiDeliveryTruck />
                </Card.Title>
              </Card.Body>
            </Card>
            <div>
              <Card.Title
                className="text-center  p-3   display-5 "
                style={{ color: "blue" }}
              >
                {delivered}
              </Card.Title>
            </div>
          </Card>
        </div>

        {/* Canceled orders */}
        <div className="d-flex flex-column">
          <span className="text-muted">Canceled Orders</span>
          <Card
            className="d-flex flex-row justify-content-between align-items-center"
            style={{ width: 210, height: 80 }}
          >
            <Card
              className="bg-dark  text-warning-color-main"
              style={{ width: 80, height: 80 }}
            >
              <Card.Body
                className="rounded"
                style={{ color: "rgb(84, 84, 67)" }}
              >
                <Card.Title className="text-center display-6 d-flex justify-content-center">
                  <MdOutlineCancel />
                </Card.Title>
              </Card.Body>
            </Card>
            <div>
              <Card.Title
                className="text-center  p-3   display-5"
                style={{ color: "rgb(84, 84, 67)" }}
              >
                {canceled}
              </Card.Title>
            </div>
          </Card>
        </div>
      </article>
    </div>
  );
};

export default OrdersCard;
