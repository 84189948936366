import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { editCategory } from "../../redux/apiCall/categoryCall";
import Loader from "../components/Loader";
import Sidebar from "../components/Sidebar";

const AdminEditCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { categoryId, categoryName } = useLocation().state;

  const [formData, setFormData] = useState({
    name: categoryName,
    image: null,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    if (e.target.name === "image") {
      setFormData({ ...formData, image: e.target.files[0] });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await dispatch(editCategory(categoryId, formData));
      navigate("/admin/categories");
    } catch (error) {
      console.error("Error editing category:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <article className="d-flex div-admin-dashboard-main">
      <Sidebar />
      <article className="d-flex flex-column" style={{ marginTop: "20px" }}>
        <article>
          <h2 style={{ fontWeight: "bold" }}>
            <span style={{ color: "#ff6016" }}>Update</span> Category
          </h2>
        </article>

        <article style={{ width: "600px", marginTop: "20px" }}>
          <form onSubmit={handleSubmit} className="w-50">
            <article className="mb-3">
              <label htmlFor="categoryName" className="form-label">
                <h5>Category Name</h5>
              </label>
              <input
                type="text"
                className="form-control"
                id="categoryName"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </article>
            <article className="mb-3" style={{ marginTop: "20px" }}>
              <label htmlFor="categoryImage" className="form-label">
                <h5>Category Image</h5>
              </label>
              <input
                type="file"
                className="form-control"
                id="categoryImage"
                name="image"
                onChange={handleChange}
              />
            </article>
            <button
              type="submit"
              className="admin-login-button"
              style={{ marginTop: "20px" }}
              disabled={loading}
            >
              {loading ? <Loader /> : "Save"}
            </button>
          </form>
        </article>
      </article>
    </article>
  );
};

export default AdminEditCategory;
