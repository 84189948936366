import React from "react";
import "./styles/login.css";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FashionZoneLogo from "../Assets/logooo.png";
import { loginUser } from "../redux/apiCall/authCall";
import { authActions } from "../redux/slice/authSlice";
import { toast } from "react-toastify";
import axios from "axios";
import { Helmet } from "react-helmet";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true; // This flag becomes false when the component unmounts

    const urlParams = new URLSearchParams(window.location.search);
    const tokenForOtp = urlParams.get("token");
    if (tokenForOtp && isMounted) {
      axios
        .post(
          `https://67.223.117.107/api/user/auth/login/${tokenForOtp}`
        )
        .then((response) => {
          if (response.data.valid && isMounted) {
            toast.success("Authenticated! Please login");
            navigate("/login");
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const SubmitHandler = (e) => {
    e.preventDefault();

    // Form validation
    if (!email || !password) {
      // console.error("Email and password are required");
      return;
    }

    dispatch(loginUser(email, password))
      .then((data) => {
        // Check if the login was successful based on the response
        if (data.status === 200) {
          dispatch(
            authActions.login(JSON.parse(localStorage.getItem("userInfo")))
          );
          // alert(data)
          // Navigate only if login is successful
          toast.success("Logged in successfully!");
          navigate("/");
        } else {
          console.error("Login failed:", data && data.error);
        }
      })
      .catch((error) => {
        console.error("Login failed:", error.message);
      });
  };
  return (
    <>
      <article className="div-admin-dashboard-main article-user-login-main-container d-flex">
        <Helmet>
          <title>Fashion Zone | Login</title>
        </Helmet>
        <article className="article-user-login-main-form d-flex flex-column align-items-center">
          <img
            src={FashionZoneLogo}
            alt="user Login Logo"
            className="img-userLoginLogo-form"
          />
          <h4 className="mt-4">Login to your Account</h4>

          <form className="d-flex flex-row flex-wrap justify-content-center">
            <article className="article-user-login-main-form-container d-flex">
              <label
                htmlFor="username"
                className="article-user-login-main-form-label"
              >
                Email
              </label>
              <input
                id="email"
                className="article-user-login-main-form-input"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="Enter your email"
                type="Email"
                required
              />
            </article>

            <article className="article-user-login-main-form-container d-flex">
              <label
                htmlFor="password"
                className="article-user-login-main-form-label"
              >
                Password
              </label>
              <input
                id="password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                placeholder="Enter your password"
                required
                className="article-user-login-main-form-input"
              />
            </article>
          </form>
          <button
            type="button"
            onClick={SubmitHandler}
            className="user-login-button"
            style={{ marginTop: "30px" }}
          >
            Login
          </button>

          <article style={{ marginBottom: "30px" }}>
            Don't have an account?
            <a className="login-page-span-a" href="/register">
              <span style={{ color: "#ff6016" }}> Sign Up</span>
            </a>
          </article>
        </article>
      </article>
    </>
  );
};

export default LoginPage;
