import { MDBFooter, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useLocation } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";
import logo from "../Assets/logooo.png";
import "./styles/footer.css";
const Footer = () => {
  const location = useLocation();

  // Conditionally render the Footer based on the route
  if (
    location.pathname.includes("/admin") ||
    location.pathname.includes("/login") ||
    location.pathname.includes("/register")
  ) {
    return null; // Don't render Navbar for admin routes
  }

  return (
    <>
      <MDBFooter
        bgColor="primary"
        className="bg-black text-white  text-center text-sm-left testttt"
      >
        <div className="class-footer-main-components ">
          <MDBRow className="class-footer-main-components-row d-flex">
            <MDBCol lg="1" md="6" className=" ">
              <img
                className="img-img-footer"
                width={80}
                height={80}
                alt="footer logo"
                src={logo}
              />
            </MDBCol>
            <MDBCol lg="3" md="6" className="mb-4 mb-md-0">
              <h4>Quick Links</h4>
              <a href="/">
                <h6 className="text-uppercase text-white">Home</h6>
              </a>
              <a href="/about">
                <h6 className="text-uppercase text-white">About us</h6>
              </a>
              <a href="/contact">
                <h6 className="text-uppercase text-white">Contact us</h6>
              </a>
            </MDBCol>

            <MDBCol lg="3" md="6" className="mb-4 mb-md-0">
              <a
                target="_blank"
                href="/terms&conditions"
                className="text-white"
              >
                <h6 className="text-uppercase">Terms & Conditions</h6>
              </a>
              <a target="_blank" href="/privacy-policy" className="text-white">
                <h6 className="text-uppercase">Privacy Policy</h6>
              </a>
            </MDBCol>

            <MDBCol lg="3" md="6" className="mb-4 mb-md-0">
              <h6 className="text-uppercase text-white">Contact Info</h6>
              <h6 className="text-uppercase text-white">+961 70 151 589</h6>
              {/* Social Media Icons */}
              <div className="text-center">
                <a
                  className="btn btn-outline-light btn-floating m-1"
                  href="https://www.facebook.com/Fashionzonelebano"
                  role="button"
                  aria-label="Visit Fashion Zone on Facebook"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebookF />
                </a>
                <a
                  className="btn btn-outline-light btn-floating m-1"
                  href="https://www.instagram.com/fashionzoneleb_/"
                  role="button"
                  aria-label="Visit Fashion Zone on Instagram"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram />
                </a>
                <a
                  className="btn btn-outline-light btn-floating m-1"
                  href="https://www.tiktok.com/@fashionzonelb"
                  role="button"
                  aria-label="Visit Fashion Zone on TikTok"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTiktok />
                </a>
              </div>
            </MDBCol>
          </MDBRow>
        </div>
      </MDBFooter>
      <div
        className="bg-black text-white text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        &copy; {new Date().getFullYear()} Copyrights:{" "}
        <a
          className="text-white"
          href="https://mdbootstrap.com/"
          target="_blank"
          rel="noreferrer"
        >
          Fashion Zone LB
        </a>
      </div>
    </>
  );
};

export default Footer;
