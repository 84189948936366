import { createSlice } from "@reduxjs/toolkit";

const categorySlice = createSlice({
  name: "category",
  initialState: {
    categories: [],
    categoryCount: 0,
    category:{},
    selectedCategoryId: null,
  },
  reducers: {
    setCategories(state, action) {
      state.categories = action.payload;
    },
    setCategory(state, action) {
      state.category = action.payload;
    },
    setCategoriesCount(state, action) {
      state.categoryCount = action.payload;
    },
    deleteCategory(state, action) {
      state.categories = state.categories.filter(
        (p) => p._id !== action.payload
      );
    },
    editCategory(state, action) {
      const updatedCategory = action.payload;
      const index = state.categories.findIndex(
        (category) => category._id === updatedCategory._id
      );
      if (index !== -1) {
        state.categories[index] = updatedCategory;
      }
    },
    addCategory(state, action) {
      const addedCategory = action.payload;
      const index = state.categories.findIndex(
        (category) => category._id === addedCategory._id
      );
      if (index !== -1) {
        state.categories[index] = addedCategory;
      }
    },
    setSelectedCategoryId: (state, action) => {
      state.selectedCategoryId = action.payload;
    },
  },
});

const categoryReducer = categorySlice.reducer;
const categoryActions = categorySlice.actions;

export { categoryActions, categoryReducer };
