import { createSlice } from "@reduxjs/toolkit";

const subcategorySlice = createSlice({
  name: "subcategory",
  initialState: {
    subcategories: [],
    subcategoryCount: 0,
    selectedSubcategoryId: null,
    hiddenSubcategoriesCount: 0,
    subcategoriesByCategoryId: [],
  },
  reducers: {
    setSubcategories(state, action) {
      state.subcategories = action.payload;
    },
    setSubcategoriesCount(state, action) {
      state.subcategoryCount = action.payload;
    },
    setHiddenSubcategoriesCount: (state, action) => {
      state.hiddenSubcategoriesCount = action.payload;
    },
    deleteSubcategory(state, action) {
      state.subcategories = state.subcategories.filter(
        (p) => p._id !== action.payload
      );
    },
    editSubcategory(state, action) {
      const updatedSubcategory = action.payload;
      const index = state.subcategories.findIndex(
        (subcategory) => subcategory._id === updatedSubcategory._id
      );
      if (index !== -1) {
        state.subcategories[index] = updatedSubcategory;
      }
    },
    addSubcategory(state, action) {
      const addedSubcategory = action.payload;
      const index = state.subcategories.findIndex(
        (subcategory) => subcategory._id === addedSubcategory._id
      );
      if (index !== -1) {
        state.subcategories[index] = addedSubcategory;
      }
    },
    toggleSubcategoryVisibility(state, action) {
      const subcategoryId = action.payload;
      const index = state.subcategories.findIndex(
        (subcategory) => subcategory._id === subcategoryId
      );
      if (index !== -1) {
        state.subcategories[index].isHidden =
          !state.subcategories[index].isHidden;
      }
    },
    setSubcategoriesByCategoryId(state, action) {
 
      state.subcategoriesByCategoryId = action.payload
    },
    setSelectedSubcategoryId: (state, action) => {
      state.selectedSubcategoryId = action.payload;
    },
  },
});

const subcategoryReducer = subcategorySlice.reducer;
const subcategoryActions = subcategorySlice.actions;

export { subcategoryActions, subcategoryReducer };
