import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { editSubcategory } from "../../redux/apiCall/subcategoryCall";
import { getAllCategories } from "../../redux/apiCall/categoryCall";
import Loader from "../components/Loader";
import Sidebar from "../components/Sidebar";

const AdminEditSubcategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subcategoryId, subcategoryName, categoryId } = useLocation().state;

  const [formData, setFormData] = useState({
    name: subcategoryName,
    categoryId: categoryId,
  });
  const [loading, setLoading] = useState(false);

  const { categories } = useSelector((state) => state.category);

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("categoryId", formData.categoryId);

      await dispatch(editSubcategory(subcategoryId, formDataToSend));
      navigate("/admin/subcategories");
    } catch (error) {
      console.error("Error editing subcategory:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <article className="d-flex div-admin-dashboard-main">
      <Sidebar />
      <article className="d-flex flex-column" style={{ marginTop: "20px" }}>
        <article>
          <h2 style={{ fontWeight: "bold" }}>
            <span style={{ color: "#ff6016" }}>Update</span> Subcategory
          </h2>
        </article>

        <article style={{ width: "600px", marginTop: "20px" }}>
          <form onSubmit={handleSubmit} className="w-50">
            <article className="mb-3">
              <label htmlFor="subcategoryName" className="form-label">
                <h5>Subcategory Name</h5>
              </label>
              <input
                type="text"
                className="form-control"
                id="subcategoryName"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </article>
            <article className="mb-3" style={{ marginTop: "20px" }}>
              <label htmlFor="categoryId" className="form-label">
                <h5>Category</h5>
              </label>
              <select
                className="form-select"
                id="categoryId"
                name="categoryId"
                value={formData.categoryId}
                onChange={handleChange}
                required
              >
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </article>
            <button
              type="submit"
              className="admin-login-button"
              style={{ marginTop: "20px" }}
              disabled={loading}
            >
              {loading ? <Loader /> : "Save"}
            </button>
          </form>
        </article>
      </article>
    </article>
  );
};

export default AdminEditSubcategory;
