import {
  getAllOrders,
  getAccepted,
  getCanceled,
  getPending,
  getDelivered,
  getOnTheWay,
} from "../../redux/apiCall/orderCall";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Cookies from "js-cookie";
import "./styles/tableorder.css";
import { toast } from "react-toastify";

const MyVerticallyCenteredModalAdmin = ({ orderData, ...props }) => {
  const dispatch = useDispatch();
  const token = Cookies.get("userToken");
  // const locations = orderData?.chosenLoc.split(",");
  // console.log("malak the queen", locations);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  // console.log(locations);

  //function that handles accepting and refusing orders
  const handleStatus = async (status) => {
    try {
      let url;
      if (status === "Confirmed") {
        url = `https://67.223.117.107/api/order/${orderData.id}/accept`;
      } else if (status === "Canceled") {
        url = `https://67.223.117.107/api/order/${orderData.id}/cancel`;
        // console.log('cancell',url.data.data)
        // dispatch(orderActions.setCanceled())
      } else if (status === "Delivered") {
        url = `https://67.223.117.107/api/order/${orderData.id}/delivered`;
      } else if (status === "On the way") {
        url = `https://67.223.117.107/api/order/${orderData.id}/ontheway`;
      } else if (status === "Reconfirmed") {
        url = `https://67.223.117.107/api/order/${orderData.id}/reconfirmed`;
      } else {
        throw new Error("Invalid status for handleStatus function");
      }

      const response = await axios.put(url, {}, { headers });
      if (response.status === 200) {
        dispatch(getAllOrders());
        dispatch(getPending());
        dispatch(getAccepted());
        dispatch(getCanceled());
        dispatch(getDelivered());
        dispatch(getOnTheWay());
        toast.success("Order status modified!");

        props.onHide();
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to modify order status");
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="table-order-admin-page" closeButton>
        <article className="d-flex flex-column">
          <h4 id="contained-modal-title-vcenter">Customer Details</h4>
          <p>Order Details #{orderData ? orderData.id.slice(-4) : null}</p>
        </article>
      </Modal.Header>

      <Modal.Body>
        <article className="d-flex flex-column user-info-details-article">
          <article
            className="d-flex flex-row"
            style={{ justifyContent: "space-around" }}
          >
            <article className="d-flex gap-2">
              <h5 style={{ fontWeight: "bold", width: "110px" }}>
                First Name&nbsp;
              </h5>
              <div
                className="text-muted user-info-details-admin rounded"
                style={{ width: "200px" }}
              >
                {orderData?.FirstName}
              </div>
            </article>

            <article className="d-flex gap-2">
              <h5 style={{ fontWeight: "bold", width: "110px" }}>Last Name </h5>
              <div
                className="text-muted user-info-details-admin rounded"
                style={{ width: "200px" }}
              >
                {orderData?.LastName}
              </div>
            </article>
          </article>

          <article
            className="d-flex flex-row"
            style={{ justifyContent: "space-around" }}
          >
            <article className="d-flex gap-2">
              <h5 style={{ fontWeight: "bold", width: "110px" }}>Email </h5>
              <div
                className="text-muted user-info-details-admin rounded"
                style={{ width: "200px" }}
              >
                {orderData?.email}
              </div>
            </article>

            <article className="d-flex gap-2">
              <h5 style={{ fontWeight: "bold", width: "110px" }}>Phone </h5>
              <div
                className="text-muted user-info-details-admin rounded"
                style={{ width: "200px" }}
              >
                {orderData?.phoneNumber}
              </div>
            </article>
          </article>
        </article>

        <Modal.Header></Modal.Header>

        {/* Location */}
        <h5
          style={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          Delivery Address
        </h5>
        <article className="d-flex flex-column user-info-details-article">
          {orderData?.location}
        </article>
      </Modal.Body>

      <Modal.Header></Modal.Header>

      <Modal.Body>
        {orderData ? (
          <div className="d-flex flex-column">
            {orderData ? (
              <div className="d-flex flex-column">
                {orderData.items.map((item, index) => (
                  <div className="d-flex" key={index}>
                    <ul className="list-unstyled border-ul-list ">
                      <div className="d-flex flex-column">
                        <li
                          className="mb-1 d-flex flex-row"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <i className="fas fa-long-arrow-alt-right d-flex me-2 "></i>
                          Item: {item.productId.name}
                        </li>

                        <li className=" mb-1 text-muted">
                          <i className=" me-3  text-info">-</i>
                          Quantity: x{item.quantity}
                        </li>
                        <li className=" mb-1 text-muted">
                          <i className=" me-3  text-info">-</i>
                          Size: {item.chosenSize}
                        </li>
                        <li className="mb-1 text-muted d-flex  align-items-center">
                          <i className="fas me-3 text-info">-</i>Color:{" "}
                          {item.chosenColor}
                        </li>
                      </div>
                      <p className="fs-5 d-flex justify-self-center justify-content-center align-items-center">
                        $ {item.partialPrice}
                      </p>
                    </ul>
                  </div>
                ))}
                <p className="rounded border-solid fs-4">
                  Total: $ {orderData.totalPrice}
                </p>
              </div>
            ) : null}
          </div>
        ) : null}
      </Modal.Body>

      <Modal.Footer
        className="d-flex px-5"
        style={{ justifyContent: "space-between" }}
      >
        {orderData?.status !== "canceled" && (
          <button
            onClick={() => handleStatus("Canceled")}
            className="btn btn-lg me-2"
            style={{ backgroundColor: "rgb(84,  84,  67)", color: "white" }}
          >
            Cancel
          </button>
        )}
        {orderData?.status === "pending" && (
          <button
            onClick={() => handleStatus("Confirmed")}
            className="btn btn-lg"
            style={{ backgroundColor: "rgb(9,  174,  0)", color: "white" }}
          >
            Confirm
          </button>
        )}
        {orderData?.status === "confirmed" && (
          <button
            onClick={() => handleStatus("On the way")}
            className="btn btn-lg"
            style={{ backgroundColor: "orange", color: "white" }}
          >
            On the Way
          </button>
        )}
        {orderData?.status === "on-the-way" && (
          <button
            onClick={() => handleStatus("Delivered")}
            className="btn btn-lg"
            style={{ backgroundColor: "blue", color: "white" }}
          >
            Delivered
          </button>
        )}
        {orderData?.status === "canceled" && (
          <button
            onClick={() => handleStatus("Reconfirmed")}
            className="btn btn-lg me-2"
            style={{ backgroundColor: "rgb(9,   174,   0)", color: "white" }}
          >
            Reconfirm
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const TableOrder = () => {
  const [modalShow, setModalShow] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.order);
  var order = orders
    .slice()
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  useEffect(() => {
    dispatch(getAllOrders());
  }, [dispatch]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 40,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "FirstName",
      headerName: "First Name",
      width: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "LastName",
      headerName: "Last Name",
      width: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "phoneNumber",
      headerName: "Phone",
      width: 110,
      align: "center",

      headerAlign: "center",
    },

    {
      field: "items",
      headerName: "Items",
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div style={{ maxHeight: "80px", overflowY: "auto" }}>
          {Array.isArray(params.value) &&
            params.value.map((item, index) => (
              <div key={index}>{`x${item.quantity}  ${item.productName}`}</div>
            ))}
        </div>
      ),
    },

    {
      field: "Location",
      headerName: "Location",
      width: 110,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      width: 110,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div style={{ textAlign: "center" }}>$ {params.row.totalPrice}</div>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      width: 110,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div className={`status-cell ${params.value.toLowerCase()}`}>
          {params.value}
        </div>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      width: 160,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          <button
            onClick={() => {
              setSelectedOrder(params.row);
              setModalShow(true);
            }}
            className="btn btn-success btn-m"
          >
            Order Details
          </button>
        </div>
      ),
    },
  ];

  const rows = order.map((order) => {
    // console.log(order);
    // const locations = order.chosenLoc.split(",");
    // const location0 = locations[0].trim();
    // const location1 = locations[1].trim();
    return {
      ...order,
      id: order._id,

      FirstName: order.firstName,
      LastName: order.lastName,
      phoneNumber: order.phoneNumber,
      email: order.email,
      items: order.items.map((item) => ({
        ...item,
        productName: item.productId.name,
      })),
      Location: order.location,
      Total: `$${order.totalPrice}`,
    };
  });

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid rows={rows} columns={columns} pageSize={5} rowHeight={90} />

      <MyVerticallyCenteredModalAdmin
        orderData={selectedOrder}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default TableOrder;
