import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { HiShoppingBag } from "react-icons/hi2";
import Cart from "./Cart";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import "./styles/ShoppingCard.css";
import { Link, useNavigate } from "react-router-dom";
import Icon from "../Assets/icons8-ok.gif";
import { createOrder } from "../redux/apiCall/orderCall";
import ConfirmCart from "./ConfirmCart";
import { toast } from "react-toastify";

function Shopping() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showCart, setShowCart] = useState(false);
  const [showConfirmCart, setShowConfirmCart] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [showGuestForm, setShowGuestForm] = useState(false); // New state for showing guest form
  const [guestInfo, setGuestInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    location: "",
  }); // New state for guest info

  const totalPrice = useSelector((state) => state.cart.totalPrice);
  const { carts } = useSelector((state) => state.cart);

  const handleClose = () => setShowCart(false);
  const handleShow = () => setShowCart(true);

  const handleProceedToCheckout = () => {
    handleClose();
    setShowGuestForm(true); // Show the guest information form
  };

  const handleGuestFormSubmit = (e) => {
    e.preventDefault();
    setShowGuestForm(false); // Hide the guest information form after submission
    setShowConfirmCart(true); // Proceed to the confirmation step
  };

  const confirmation = () => {
    const finalOrder = [];
    carts.forEach((item) => {
      finalOrder.push({
        productId: item.productId,
        quantity: item.quantity,
        chosenColor: item.chosenColor,
        chosenSize: item.chosenSize,
      });
    });

    // Log the guestInfo state before sending
    // console.log("Sending guestInfo:", guestInfo);

    // Include guest info in the order creation
    dispatch(createOrder(finalOrder, guestInfo)).then((data) => {
      // console.log("Order creation response:", data); // Log the response
      if (data !== 400) {
        setShowConfirmCart(false);
        setLgShow(true);
      }
    });
    setShowConfirmCart(false);
    toast.success("Order is sent!");
  };

  const total = totalPrice + 4;
  return (
    <>
      <HiShoppingBag
        className="fi-shopping-card-icon text-white"
        onClick={handleShow}
      />

      <Offcanvas show={showCart} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Shopping Cart</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Cart />
        </Offcanvas.Body>
        <article className="offcanvas-footer-total justify-content-between">
          <article className="d-flex flex-column">
            <article className="d-flex justify-content-between align-items-center">
              <span className="price-product-detail-title">Total:</span>
              <span className="price-product-detail ">${totalPrice}</span>

              
            </article>
            <article className="d-flex justify-content-between align-items-center">
            <h5 className="price-product-detail-title">Delivery Charge: </h5>
            <span style={{fontSize:"20px"}}> 4$ </span>
            </article>
            <hr></hr>
            <span className="mb-3" style={{fontSize:"12px", color:"#4d4d4d"}}>delivery charge is 4$ all over lebanon and it is not included in the total</span>
          </article>
          {carts.length > 0 ? (
            <Button
              className="user-login-button w-100 p-1 mb-0"
              onClick={handleProceedToCheckout}
            >
              Proceed to checkout
            </Button>
          ) : null}
        </article>
      </Offcanvas>

      <Modal show={showConfirmCart} onHide={() => setShowConfirmCart(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ConfirmCart />
        </Modal.Body>
        <Modal.Body className="d-flex flex-column" style={{ gap: "20px" }}>
          <article
            className="d-flex flex-row"
            style={{ justifyContent: "space-between" }}
          >
            <h5 style={{ marginBottom: "0" }}>Price</h5>
            <h5 >
              <span >$ </span>
              {totalPrice}
            </h5>
          </article>
          <article
            className="d-flex flex-row"
            style={{ justifyContent: "space-between" }}
          >
            <h5 style={{ marginBottom: "0" }}>Delivery Charge</h5>
            <h5 >
              <span >$ </span>
              4
            </h5>
          </article>
          <article
            className="d-flex flex-row"
            style={{ justifyContent: "space-between" }}
          >
            <h5 style={{ marginBottom: "0" }}>Total</h5>
            <h5 style={{ color: "#ff6016" }} >
              <span style={{ color: "#ff6016" }}>$ </span>
              {total}
            </h5>
          </article>
        </Modal.Body>

        <Modal.Footer style={{ justifyContent: "space-between" }}>
          <Button variant="secondary" onClick={() => setShowConfirmCart(false)}>
            Close
          </Button>
          <button
            className="confirm-order-button"
            onClick={() => confirmation()}
          >
            Confirm Order
          </button>
        </Modal.Footer>
      </Modal>

      {/* Guest Form Modal */}
      <Modal show={showGuestForm} onHide={() => setShowGuestForm(false)}>
        <Modal.Header closeButton>
          <h4>Customer's Information</h4>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleGuestFormSubmit}>
            <div className="mb-3">
              <label htmlFor="guestFirstName" className="form-label">
                First Name
              </label>
              <input
                type="text"
                required
                className="form-control"
                id="guestFirstName"
                onChange={(e) =>
                  setGuestInfo({ ...guestInfo, firstName: e.target.value })
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="guestLastName" className="form-label">
                Last Name
              </label>
              <input
                required
                type="text"
                className="form-control"
                id="guestLastName"
                onChange={(e) =>
                  setGuestInfo({ ...guestInfo, lastName: e.target.value })
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="guestEmail" className="form-label">
                Email
              </label>
              <input
                required
                type="email"
                className="form-control"
                id="guestEmail"
                onChange={(e) =>
                  setGuestInfo({ ...guestInfo, email: e.target.value })
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="guestPhoneNumber" className="form-label">
                Phone Number
              </label>
              <input
                required
                type="tel"
                className="form-control"
                id="guestPhoneNumber"
                onChange={(e) =>
                  setGuestInfo({ ...guestInfo, phoneNumber: e.target.value })
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="guestLocation" className="form-label">
                Location
              </label>
              <input
                required
                type="text"
                className="form-control"
                id="guestLocation"
                onChange={(e) =>
                  setGuestInfo({ ...guestInfo, location: e.target.value })
                }
              />
            </div>
            <div className="d-flex flex-row justify-content-center">
              <button type="submit" className="admin-login-button">
                Continue
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Shopping;
