import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles/home.css";
import khalil from "../Assets/khalil.svg";
import Category from "../components/Category";
import { getAllCategories } from "../redux/apiCall/categoryCall";
import Carousel from "../components/Carousel";
import { getRecommendedProducts } from "../redux/apiCall/productCall";
import { Helmet } from "react-helmet";
const Home = () => {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.category);
  const { recommendedCarousel } = useSelector((state) => state.product);
  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getRecommendedProducts());
  }, [dispatch]);

  const handleExploreNow = () => {
    const shopByCategorySection = document.getElementById("shopByCategory");
    if (shopByCategorySection) {
      shopByCategorySection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <article className="Home-main-component">
      <Helmet>
        <title>Fashion Zone | Home</title>
      </Helmet>
      <article className="d-flex flex-column justify-content-center align-items-center home-page-main-container">
        <header className=" d-flex align-items-center justify-content-between home-page-main-container-header">
          <article className="flex-text-class d-flex flex-column text-white">
            <h1 className="title-fashion-zone">For Alpha Males & Females</h1>
            <p className="description-hero">
              Where Trends Come to Life: Explore Endless Styles at Fashion Zone.
            </p>
            <button
              type="button"
              className="admin-login-button button-hero ml-10"
              onClick={handleExploreNow} // Attach handleExploreNow function to onClick event
            >
              Explore Now
            </button>
          </article>
          <img
            src={khalil}
            width={490}
            height={750}
            alt="khalil"
            className="khalil-hero mt-5"
          />
        </header>
      </article>

      <article
        id="shopByCategory"
        className="main-category-container-home d-flex flex-column justify-content-center align-items-center"
      >
        <article className="d-flex flex-column justify-content-center align-items-center">
          <article className="d-flex gap-3 mt-5">
            <h1>Shop</h1>
            <h1 className="another-color"> By Category</h1>
          </article>
          <article className="d-flex another-component">
            {categories.map((category) => (
              <Category
                key={category._id}
                category={category.name}
                image={category.image}
                categoryId={category._id}
                // onClick={handleClick(category.name)}
              />
            ))}
          </article>
        </article>

        {recommendedCarousel.length > 0 && (
          <article style={{ width: "90%", marginTop: "20px" }}>
            <article className="d-flex gap-3 justify-content-center align-items-center">
              <h1>Best</h1>
              <h1 className="another-color"> Sellers</h1>
            </article>
            <article></article>
            <Carousel />
          </article>
        )}
      </article>
    </article>
  );
};

export default Home;
