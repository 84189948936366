import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
  name: "order",
  initialState: {
    orders: [],
    orderCount: 0,
    pending: 0,
    accepted: 0,
    canceled: 0,
    delivered: 0,
    ontheway: 0,
    getResponse: false,
  },
  reducers: {
    setOrders(state, action) {
      state.orders = action.payload;
    },
    setOrdersCount(state, action) {
      state.orderCount = action.payload;
    },
    setPending(state, action) {
      state.pending = action.payload;
    },
    setAccepted(state, action) {
      state.accepted = action.payload;
    },
    setCanceled(state, action) {
      state.canceled = action.payload;
    },
    setDelivered(state, action) {
      state.delivered = action.payload;
    },
    setOnTheWay(state, action) {
      state.ontheway = action.payload;
    },
    setResponse(state, action) {
      state.getResponse = action.payload;
    },
  },
});

const orderReducer = orderSlice.reducer;
const orderActions = orderSlice.actions;

export { orderActions, orderReducer };
