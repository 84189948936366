import React from "react";
import "./styles/color.css";

const Color = ({ children }) => {
  const divStyle = {
    backgroundColor: children,
  };
  return (
    <div
      // onClick={() => props(children)}
      style={divStyle}
      className="rounded-circle color-circle-main"
    ></div>
  );
};
export default Color;
