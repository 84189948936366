import React, { useEffect } from "react";
import TableOrder from "../components/TableOrder";
import Sidebar from "../components/Sidebar.jsx";
import { useDispatch } from "react-redux";
import { getAllOrders } from "../../redux/apiCall/orderCall.jsx";
import OrdersCard from "../components/OrdersCard.jsx";

const AdminOrder = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrders());
  }, [dispatch]);

  return (
    <div className="d-flex div-admin-dashboard-main">
      <Sidebar />
      <div className="d-flex flex-column div-admin-dashboard-main-child">
        <OrdersCard />

        <div style={{ marginTop: "20px" }}>
          <TableOrder />
        </div>
      </div>
    </div>
  );
};

export default AdminOrder;
