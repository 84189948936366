import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes/routes";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer.jsx";

function App() {
  return (
    <BrowserRouter>
      <article className="body d-flex flex-column">
        <Navbar />
        <article className="App d-flex flex-column justify-self-center align-self-center">
          <AppRoutes />
        </article>
        <Footer />
      </article>
    </BrowserRouter>
  );
}

export default App;
