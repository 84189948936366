import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import { CFormCheck } from "@coreui/react";
import { RiShoppingBagFill } from "react-icons/ri";
import "./styles/singleproduct.css";
import { useSelector, useDispatch } from "react-redux";
import { getProduct, fetchSizeDetails } from "../redux/apiCall/productCall";
import { useParams } from "react-router-dom";
import { cartActions } from "../redux/slice/cartSlice";
import { Helmet } from "react-helmet";

const SingleProduct = () => {
  const dispatch = useDispatch();
  const { productId } = useParams();

  const product = useSelector((state) => state.product.product);
  const sizeDetails = useSelector((state) => state.product.sizeDetails);
  const cartDetails = useSelector((state) => state.cart.carts);

  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [quantity, setQuantity] = useState(0); // Initialize quantity to  1
  const [colorQuantity, setColorQuantity] = useState(0);
  const [fake, setFake] = useState(0); // Initialize quantity to  1

  const [cartColor, setCartColor] = useState(null);
  const [cartSize, setCartSize] = useState(null);
  const [cartQuantity, setCartQuantity] = useState(null);

  const availableSizes = product?.sizes;
  const availableColors = product?.colors;

  useEffect(() => {
    dispatch(getProduct(productId));
    if (selectedSize) {
      dispatch(fetchSizeDetails(productId, selectedSize));
    }
  }, [dispatch, productId, selectedSize]);

  // console.log("sizeDetails, ", sizeDetails);

  const handleColorChange = (event) => {
    var productFound = -1;

    var cartArray = cartDetails.slice();
    // Make a shallow copy of cartDetails

    if (cartArray.length > 0) {
      productFound = cartArray.findIndex(
        (each) =>
          each.productId === productId &&
          each.chosenColor === cartColor &&
          each.chosenSize === cartSize
      );
    }
    if (productFound === -1) {
      setFake(sizeDetails[event.target.value]);
    } else {
      var newQuantity =
        sizeDetails[event.target.value] - cartArray[productFound].quantity;
      setFake(newQuantity);
    }

    setColorQuantity(sizeDetails[event.target.value]);
    setSelectedColor(event.target.value);
    setQuantity(0);
    setCartColor(event.target.value);
  };

  const decrementQuantity = () => {
    if (quantity > 0) {
      // Ensure quantity doesn't go below  1
      setQuantity(quantity - 1);
      setCartQuantity(quantity - 1);
      setFake((prev) => prev + 1);
    }
  };
  // Function to handle incrementing the quantity
  const incrementQuantity = () => {
    if (quantity <= colorQuantity) {
      setQuantity(quantity + 1);
      setCartQuantity(quantity + 1);
      setFake((prev) => prev - 1);
    }
  };

  const AddtoCart = () => {
    setColorQuantity((prev)=>prev-quantity)
    
    setQuantity(0);

    const productObject = {
      productId,
      quantity: cartQuantity,
      chosenColor: cartColor,
      chosenSize: cartSize,
      productData: product,
    };
    var checkProductIndex = -1;

    if (cartDetails.length > 0) {
      checkProductIndex =
        cartDetails.length > 0 &&
        cartDetails.findIndex(
          (each) =>
            each.productId === productId &&
            each.chosenColor === cartColor &&
            each.chosenSize === cartSize
        );
    }

    var cartArray = cartDetails.slice(); // Make a shallow copy of cartDetails

    if (checkProductIndex === -1) {
      // adding new product
      cartArray.push(productObject);
    } else {
      //incrementing the quantity
      cartArray[checkProductIndex] = {
        ...cartArray[checkProductIndex],
        quantity: cartArray[checkProductIndex].quantity + cartQuantity,
      }; // Update the value at the specified index
    }

    dispatch(cartActions.setCarts(cartArray));
    sessionStorage.setItem("cart", JSON.stringify(cartArray));
  };

  return (
    <>
      <article className="single-product-main-component">
        <Helmet>
          <title>{`Fashion Zone | ${product?.name}`}</title>
        </Helmet>
        <article className="single-product-main-content">
          <article style={{ flex: "1", justifyContent: "center" }}>
            <div className="asf ">
              <Carousel
                activeIndex={activeIndex}
                onSelect={(selectedIndex) => setActiveIndex(selectedIndex)}
                indicators={true}
                controls={false}
                // interval={3000}
                className="mb-3"
              >
                {product?.images.map((image, index) => (
                  <Carousel.Item key={index}>
                    <img
                      className="carousel-image thumbnail-image"
                      src={image.path}
                      alt={`Slide ${index}`}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>

              <div className="d-flex justify-content-center">
                {product?.images.map((image, index) => (
                  <img
                    key={index}
                    src={image.path}
                    alt={`Thumbnail ${index}`}
                    className="m-2 thumbnail-image"
                    style={{ width: "15%", cursor: "pointer" }}
                    onClick={() => setActiveIndex(index)}
                  />
                ))}
              </div>
            </div>
          </article>

          <article className="d-flex flex-column" style={{ flex: 2 }}>
            <article className="d-flex flex-column" style={{ gap: "2px" }}>
              <article>
                <h4 style={{ margin: "0px" }}>{product?.name}</h4>
              </article>

              <article className="d-flex flex-row">
                <span className="text-muted">
                  {product?.subcategoryId?.name}
                </span>
              </article>

              <article style={{ textAlign: "justify" }}>
                {product?.description}
              </article>
            </article>

            <hr style={{ color: "#818181" }} />

            <article className="d-flex flex-row">
              <h5 style={{ fontFamily: "title" }}>
                <span style={{ color: "#ff6016" }}>$ &nbsp;</span>{" "}
                {product?.price}
              </h5>
            </article>
            <hr style={{ color: "#818181" }} />

            <article className="d-flex flex-column" style={{ gap: "30px" }}>
              <article className="d-flex flex-row">
                <article>
                  {/* {console.log("firstfbcjas", availableSizes)} */}
                  {availableSizes?.map((size, index) => (
                    <CFormCheck
                      key={index}
                      inline
                      type="radio"
                      name="inlineRadioOptions"
                      id={`size-${size}`}
                      value={size}
                      label={size}
                      checked={selectedSize === size}
                      onChange={() => {
                        setSelectedSize(size);
                        setQuantity(0);
                        setFake(0);
                        setSelectedColor("");
                        setCartSize(size);
                      }}
                    />
                  ))}
                </article>
              </article>

              <article className="d-flex flex-row row-wrap">
                <article></article>
              </article>
            </article>
            <select
              onChange={handleColorChange}
              value={selectedColor}
              style={{
                backgroundColor: "#ff6016",
                color: "white",
                fontWeight: "500",
                borderColor: "#ff6016",
                width: "130px",
                height: "40px",
                display: "flex",
                padding: "5px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                marginBottom: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                cursor: "pointer",
              }}
            >
              <option
                value=""
                style={{
                  backgroundColor: "white",
                  color: "#ff6016",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Select a color
              </option>
              {Object.keys(sizeDetails).map((color) => (
                <option
                  key={color}
                  value={color}
                  style={{
                    backgroundColor: "white",
                    color: "#ff6016",
                    cursor: "pointer",
                  }}
                >
                  {color}
                </option>
              ))}
            </select>
            <article
              className="d-flex flex-row"
              style={{
                marginTop: "20px",
                height: "30px",
              }}
            >
              {selectedColor && (
                <>
                  <article>Quantity Left:&nbsp;</article>
                  <h5 style={{ color: "#ff6016" }}>{fake}</h5>
                </>
              )}
            </article>

            <hr style={{ color: "#818181" }} />

            <article
              className="d-flex flex-row align-items-center"
              style={{ marginTop: "10px" }}
            >
              <article>
                {selectedColor ? (
                  <div style={{ width: "150px" }}>
                    <div className="">
                      <div className="col-md-6">
                        <div className="d-flex justify-content-center align-items-center">
                          <button
                            className="btn btn-outline-primary me-2"
                            type="button"
                            onClick={decrementQuantity}
                          >
                            -
                          </button>
                          <span className="">{quantity}</span>
                          <button
                            className="btn btn-outline-primary ms-2"
                            type="button"
                            disabled={fake === 0}
                            onClick={incrementQuantity}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div style={{ width: "150px" }}>
                    <div className="">
                      <div className="col-md-6">
                        <div className="d-flex justify-content-center align-items-center">
                          <button
                            className="btn btn-outline-primary me-2"
                            type="button"
                            disabled={true}
                            onClick={decrementQuantity}
                          >
                            -
                          </button>
                          <span className="">{quantity}</span>
                          <button
                            className="btn btn-outline-primary ms-2"
                            type="button"
                            disabled={true}
                            onClick={incrementQuantity}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </article>
              <article>
                {quantity === 0 ? (
                  <button
                    className="add-to-cart-single-product-button"
                    onClick={() => AddtoCart()}
                    disabled={true}
                    style={{ cursor: "pointer" }}
                  >
                    <RiShoppingBagFill style={{ color: "#black" }} />
                    Add To Cart
                  </button>
                ) : (
                  <button
                    className="add-to-cart-single-product-button"
                    onClick={() => AddtoCart()}
                  >
                    <RiShoppingBagFill style={{ color: "#black" }} />
                    Add To Cart
                  </button>
                )}
              </article>
            </article>
          </article>
        </article>
      </article>
    </>
  );
};

export default SingleProduct;
