import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSubcategory } from "../../redux/apiCall/subcategoryCall";
import { useNavigate } from "react-router-dom";
import { getAllCategories } from "../../redux/apiCall/categoryCall";

import Loader from "../components/Loader";
import Sidebar from "../components/Sidebar";

const AdminAddSubcategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [subcategoryName, setSubcategoryName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const { categories } = useSelector((state) => state.category);

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  const handleNameChange = (e) => {
    setSubcategoryName(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const subcategoryData = {
        name: subcategoryName,
        categoryId: selectedCategory,
      };

      await dispatch(addSubcategory(subcategoryData));
      navigate("/admin/subcategories");

      setSubcategoryName("");
      setSelectedCategory("");
      setLoading(false);
    } catch (error) {
      console.error("Error adding subcategory:", error);
      setLoading(false);
    }
  };

  return (
    <article className="d-flex div-admin-dashboard-main">
      <Sidebar />
      <article className="d-flex flex-column" style={{ marginTop: "20px" }}>
        <article>
          <h2 style={{ fontWeight: "bold" }}>
            <span style={{ color: "#ff6016" }}>Add</span> Subcategory
          </h2>
        </article>

        <article style={{ width: "600px", marginTop: "20px" }}>
          <form onSubmit={handleSubmit} className="w-50">
            <article className="mb-3">
              <label htmlFor="subcategoryName" className="form-label">
                <h5>Subcategory Name</h5>
              </label>
              <input
                type="text"
                className="form-control"
                id="subcategoryName"
                name="name"
                value={subcategoryName}
                onChange={handleNameChange}
                required
              />
            </article>
            <article className="mb-3" style={{ marginTop: "20px" }}>
              <label htmlFor="categoryId" className="form-label">
                <h5>Category</h5>
              </label>
              <select
                className="form-select"
                id="categoryId"
                name="categoryId"
                value={selectedCategory}
                onChange={handleCategoryChange}
                required
              >
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </article>
            <button
              type="submit"
              className="admin-login-button"
              style={{ marginTop: "20px" }}
              disabled={loading}
            >
              {loading ? <Loader /> : "Save"}
            </button>
          </form>
        </article>
      </article>
    </article>
  );
};

export default AdminAddSubcategory;
