import React from "react";
import { Link } from "react-router-dom";
import "./styles/productcard.css";

const ProductCard = ({
  product,
  productQuantity,
  images = [],
  price,
  productId,
}) => {
  const imagePath = images.length > 0 ? images[0].path : "";

  return (
    <>
      {console.log("quantityy", productQuantity)}
      {productQuantity > 0 ? (
        <Link to={`/product/${productId}`} style={{border:"2px solid white", backgroundColor:"white" ,width:"250px", paddingRight:"5px", paddingLeft:"5px"}}>
          <article className="category-single-component-main">
            {/* <article className="dark-overlay d-flex flex-column">
           
          </article> */}
            <img
              className="image-for-category-single"
              src={imagePath}
              alt={product}
              style={{ objectFit: "contain" }}
            />
          </article>
          <hr></hr>
          <div className="d-flex justify-content-between mt-3 mb-2" >
            <p
              style={{ color: "#ff6016", width: "60%" }}
            >
              {product}
            </p>

            <p className="overlay-text "  style={{ color: "#ff6016", fontSize:"23x"}}>$ {price}</p>
          </div>
        </Link>
      ) : (
        <article className="category-single-component-main">
          <article className="dark-overlay d-flex flex-column">
            <p
              className="overlay-text"
              style={{ color: "#ff6016", width: "80%", margin: "0 auto" }}
            >
              {product}
            </p>
            <p className="overlay-text">Out Of Stock</p>
          </article>
          <img
            className="image-for-category-single"
            src={imagePath}
            alt={product}
            style={{ objectFit: "contain" }}
          />
        </article>
      )}
    </>
  );
};

export default ProductCard;
