import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createProduct } from "../../redux/apiCall/productCall";
import { useNavigate } from "react-router-dom";
import { getAllSubcategories } from "../../redux/apiCall/subcategoryCall";

import Loader from "../components/Loader";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "white",
  border: "2px solid #ff6016",
  borderRadius: "5px",
  boxShadow: 24,
  p: 2,
  zIndex: 1300,
};

const AdminAddProduct = () => {
  //modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);

  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [quantity, setQuantity] = useState("");

  const [available, setAvailable] = useState([]);
  const [price, setPrice] = useState("");
  const [images, setImages] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState("");

  const { subcategories } = useSelector((state) => state.subcategory);

  useEffect(() => {
    // console.log("Fetching all subcategories...");
    dispatch(getAllSubcategories());
  }, [dispatch]);

  const handleImageChange = (e) => {
    // console.log("Handling image change...");
    const files = Array.from(e.target.files);
    // console.log("Selected images:", files);
    setImages(files);
  };

  const handleAddProduct = () => {
    const product = {
      size: size.toLowerCase(),
      color: color.toLowerCase(),
      quantity: quantity,
    };
    setAvailable((prev) => [...prev, product]);

    if (!sizes.includes(size)) {
      setSizes((prev) => {
        const newSizes = [...prev, size];
        // Define the order for string sizes
        const sizeOrder = { xs: 1, s: 2, m: 3, l: 4, xl: 5, xxl: 6 };

        // Sorting logic for both string and numerical sizes
        newSizes.sort((a, b) => {
          // Check if sizes are numerical
          if (!isNaN(a) && !isNaN(b)) {
            // Both are numbers, sort numerically
            return Number(a) - Number(b);
          } else if (!isNaN(a)) {
            // 'a' is a number, 'b' is a string, sort numbers first
            return -1;
          } else if (!isNaN(b)) {
            // 'b' is a number, 'a' is a string, sort numbers first
            return 1;
          } else {
            // Both are strings, sort according to predefined order
            return sizeOrder[a] - sizeOrder[b];
          }
        });
        return newSizes;
      });
    }

    if (!colors.includes(color)) {
      setColors((prev) => [...prev, color]);
    }

    handleClose();
  };
  useEffect(() => {}, [available, colors, sizes]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("price", price);
    formData.append("subcategoryId", selectedSubcategory);

    images.forEach((image, index) => {
      formData.append(`images`, image);
    });

    formData.append("available", JSON.stringify(available));
    formData.append("colors", JSON.stringify(colors));
    formData.append("sizes", JSON.stringify(sizes));
    const formDataObject = {};
    for (let [key, value] of formData.entries()) {
      formDataObject[key] = value;
    }
    // console.log("from formData", formDataObject);
    try {
      await dispatch(createProduct(formData));
      // console.log("Product created successfully");
      navigate("/admin/main");
    } catch (error) {
      console.error("Error creating product:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <article className="d-flex div-admin-dashboard-main">
      <Sidebar />
      <article className="d-flex flex-column" style={{ marginTop: "20px" }}>
        <article>
          <h2 style={{ fontWeight: "bold" }}>
            <span style={{ color: "#ff6016" }}>Add</span> Product
          </h2>
        </article>

        <article style={{ width: "600px", marginTop: "20px" }}>
          <form onSubmit={handleSubmit} className="w-50">
            <article className="mb-3">
              <label htmlFor="name" className="form-label">
                <h5>Product Name</h5>
              </label>
              <input
                className="form-control"
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </article>

            <article className="mb-3">
              <label htmlFor="description" className="form-label">
                <h5>Description</h5>
              </label>
              <textarea
                className="form-control"
                id="description"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </article>

            <article className="mb-3">
              <label htmlFor="price" className="form-label">
                <h5>Price</h5>
              </label>
              <input
                className="form-control"
                type="number"
                id="price"
                name="price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
              />
            </article>

            <article className="mb-3">
              <label htmlFor="images" className="form-label">
                <h5>Images</h5>
              </label>
              <input
                className="form-control"
                type="file"
                id="images"
                name="images"
                multiple
                onChange={handleImageChange}
                required
              />
            </article>

            {/* Additional form fields for colors, sizes, and quantities */}
            <Button onClick={handleOpen}>Add Available Size and color</Button>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <article className="d-flex flex-column justify-content-center gap-4">
                  <article className="d-flex flex-column">
                    <h5>Size</h5>
                    <input
                      type="text"
                      required
                      placeholder="Enter a size"
                      onChange={(e) => setSize(e.target.value)}
                      style={{
                        borderRadius: "5px",
                        border: "0.2px solid lightgrey",
                        padding: "5px",
                      }}
                    />
                  </article>
                  <article className="d-flex flex-column">
                    <h5>Color</h5>
                    <input
                      required
                      type="text"
                      placeholder="Enter a color"
                      onChange={(e) => setColor(e.target.value)}
                      style={{
                        borderRadius: "5px",
                        border: "0.2px solid lightgrey",
                        padding: "5px",
                      }}
                    />
                  </article>
                  <article className="d-flex flex-column">
                    <h5>Quantity</h5>
                    <input
                      required
                      type="number"
                      placeholder="Enter the quantity"
                      onChange={(e) => setQuantity(e.target.value)}
                      style={{
                        borderRadius: "5px",
                        border: "0.2px solid lightgrey",
                        padding: "5px",
                      }}
                    />
                  </article>
                </article>
                <article className="d-flex justify-content-center">
                  <button
                    className="btn btn-success mt-5"
                    onClick={handleAddProduct}
                  >
                    Add
                  </button>
                </article>
              </Box>
            </Modal>

            <article className="mb-3" style={{ marginTop: "20px" }}>
              <label htmlFor="subcategory" className="form-label">
                <h5>Subcategory</h5>
              </label>
              <select
                className="form-select"
                id="subcategory"
                name="subcategory"
                value={selectedSubcategory}
                onChange={(e) => setSelectedSubcategory(e.target.value)}
                required
              >
                <option value="">Select Subcategory</option>
                {subcategories.map((subcategory) => (
                  <option key={subcategory._id} value={subcategory._id}>
                    {subcategory.name}
                  </option>
                ))}
              </select>
            </article>

            <button
              type="submit"
              className="admin-login-button"
              style={{ marginTop: "20px" }}
              disabled={loading}
            >
              {loading ? <Loader /> : "Save"}
            </button>
          </form>
        </article>
      </article>
    </article>
  );
};

export default AdminAddProduct;
