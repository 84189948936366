import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import "./styles/product.css";
import Color from "../../components/Color";

const ProductModal = ({ show, onHide, loading }) => {
  const { product } = useSelector((state) => state.product);
  // console.log("prrr", product);

  useEffect(() => {
    if (product) {
      // console.log(product);
    }
  }, [product]);

  // console.log("Product inside modal:", product); // Debugging line

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="table-order-admin-page">
          <article className="d-flex flex-column">
            <h4 id="contained-modal-title-vcenter">Product Details</h4>
          </article>
        </Modal.Header>

        <Modal.Body className="d-flex flex-column" style={{ gap: "8px" }}>
          <Table striped bordered hover responsive>
            <thead
              style={{
                textAlign: "center",
                width: "100px",
              }}
            >
              <tr style={{ textAlign: "center", width: "100px" }}>
                <th className="bg-dark" style={{ color: "#ff6016" }}>
                  Product
                </th>
                <th className="bg-dark" style={{ color: "#ff6016" }}>
                  Subcategory
                </th>
                <th className="bg-dark" style={{ color: "#ff6016" }}>
                  Price
                </th>
                <th className="bg-dark" style={{ color: "#ff6016" }}>
                  Sizes
                </th>
                <th className="bg-dark" style={{ color: "#ff6016" }}>
                  Colors
                </th>
                <th className="bg-dark" style={{ color: "#ff6016" }}>
                  T.Quantity
                </th>
                <th className="bg-dark" style={{ color: "#ff6016" }}>
                  Recommended
                </th>
                <th className="bg-dark" style={{ color: "#ff6016" }}>
                  Hidden
                </th>
                <th className="bg-dark" style={{ color: "#ff6016" }}>
                  Description
                </th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              <td style={{ border: "1px solid gainsboro", padding: "8x" }}>
                {product?.name}
              </td>
              <td style={{ border: "1px solid gainsboro", padding: "8x" }}>
                {product?.subcategoryId.name}
              </td>
              <td style={{ border: "1px solid gainsboro", padding: "8x" }}>
                {product?.price}
              </td>
              <td style={{ border: "1px solid gainsboro", padding: "8x" }}>
                {product?.sizes.join(", ")}
              </td>
              <td
                style={{
                  border: "1px solid gainsboro",
                  padding: "8x",
                }}
              >
                <div className="d-flex flex-row gap-2">
                  {product?.colors.map((color, index) => (
                    <Color key={index}>{color}</Color>
                  ))}
                </div>
              </td>
              <td style={{ border: "1px solid gainsboro", padding: "8x" }}>
                {product?.totalQuantity}
              </td>
              <td style={{ border: "1px solid gainsboro", padding: "8x" }}>
                {product?.isRecommended ? "Yes" : "No"}
              </td>
              <td style={{ border: "1px solid gainsboro", padding: "8x" }}>
                {product?.isHidden ? "Yes" : "No"}
              </td>
              <td
                style={{
                  textAlign: "justify",
                  border: "1px solid gainsboro",
                  padding: "8px",
                }}
              >
                {product?.description}
              </td>
            </tbody>
          </Table>
          Available
          <Table
            striped
            bordered
            hover
            responsive
            style={{
              textAlign: "center",
            }}
          >
            <thead>
              <tr>
                <th className="bg-dark" style={{ color: "#ff6016" }}>
                  Colors
                </th>
                <th className="bg-dark" style={{ color: "#ff6016" }}>
                  Sizes
                </th>
                <th className="bg-dark" style={{ color: "#ff6016" }}>
                  Quantity
                </th>
              </tr>
            </thead>
            <tbody>
              {product?.available.map((item, index) => (
                <tr key={index}>
                  <article
                    className="d-flex flex-row justify-content-center"
                    style={{ borderLeft: "1px solid gainsboro" }}
                  >
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Color>{item.color}</Color>
                    </td>
                  </article>
                  <td style={{ border: "1px solid gainsboro", padding: "8px" }}>
                    {item.size}
                  </td>
                  <td style={{ border: "1px solid gainsboro", padding: "8px" }}>
                    {item.quantity}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          Images
          <article
            className="d-flex flex-row"
            style={{ justifyContent: "space-around" }}
          >
            {product?.images.map((imageUrl, index) => (
              <img
                key={index}
                src={imageUrl.path}
                alt={`Product ${index + 1}`}
                style={{
                  width: "100px",
                  height: "100px",
                  marginRight: "10px",
                }}
              />
            ))}
          </article>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProductModal;
