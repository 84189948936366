import axios from "axios";
import { orderActions } from "../slice/orderSlice";
import { toast } from "react-toastify";
import { cartActions } from "../slice/cartSlice";
import Cookies from "js-cookie";

export const getAllOrders = () => {
  return async (dispatch) => {
    try {
      const data = await axios.get(
        "https://67.223.117.107/api/order",
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("userToken")}`,
          },
        }
      );

      dispatch(orderActions.setOrders(data.data.data));
      dispatch(orderActions.setOrdersCount(data.data.data.length));
    } catch (error) {
      console.error("Error fetching orders:", error);
      toast.error("Bad connection or server error");
    }
  };
};

//get canceled
export const getCanceled = () => {
  return async (dispatch) => {
    try {
      const data = await axios.get(
        "https://67.223.117.107/api/order/orders/canceled",
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("userToken")}`,
          },
        }
      );
      dispatch(orderActions.setCanceled(data.data.count));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // console.log("No canceled orders found.");
      } else {
        console.error("Error fetching orders:", error);
        toast.error("Bad connection or server error");
      }
    }
  };
};

//get accepted
export const getAccepted = () => {
  return async (dispatch) => {
    try {
      const data = await axios.get(
        "https://67.223.117.107/api/order/orders/accepted",
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("userToken")}`,
          },
        }
      );

      // console.log('data here',data.data)
      dispatch(orderActions.setAccepted(data.data.count));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // console.log("No accepted orders found.");
      } else {
        console.error("Error fetching orders:", error);
        toast.error("Bad connection or server error");
      }
    }
  };
};

//get pending
export const getPending = () => {
  return async (dispatch) => {
    try {
      const data = await axios.get(
        "https://67.223.117.107/api/order/orders/pending",
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("userToken")}`,
          },
        }
      );
      // console.log('data here',data.data)
      dispatch(orderActions.setPending(data.data.count));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // console.log("No pending orders found.");
      } else {
        console.error("Error fetching orders:", error);
        toast.error("Bad connection or server error");
      }
    }
  };
};

//get delivered
export const getDelivered = () => {
  return async (dispatch) => {
    try {
      const data = await axios.get(
        "https://67.223.117.107/api/order/orders/delivered",
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("userToken")}`,
          },
        }
      );

      dispatch(orderActions.setDelivered(data.data.count));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // console.log("No delivered orders found.");
      } else {
        console.error("Error fetching orders:", error);
        toast.error("Bad connection or server error");
      }
    }
  };
};

//get delivered
export const getOnTheWay = () => {
  return async (dispatch) => {
    try {
      const data = await axios.get(
        "https://67.223.117.107/api/order/orders/ontheway",
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("userToken")}`,
          },
        }
      );
      // console.log("first", data);
      dispatch(orderActions.setOnTheWay(data.data.count));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // console.log("No on the way orders found.");
      } else {
        console.error("Error fetching orders:", error);
        toast.error("Bad connection or server error");
      }
    }
  };
};

export const getAllOrdersByUser = (userId, token) => {
  return async (dispatch) => {
    try {
      const data = await axios.get(
        `https://67.223.117.107/api/order/myorders/${userId}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("hello", data.data);
      dispatch(orderActions.setOrders(data.data));
      if (data.status === 200) {
        dispatch(orderActions.setResponse(true));
      }

      return data;
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };
};

export const createOrder = (items, guestInfo) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        // `https://67.223.117.107/api/order`,
        `https://67.223.117.107/api/order`,

        {
          items: items,
          guestInfo: guestInfo,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("Received guestInfo:", guestInfo);

      // console.log("order response", response.data);
      if (response.status === 201) {
        sessionStorage.removeItem("cart");
        sessionStorage.removeItem("total");
        dispatch(cartActions.emptyCarts());
      }
      return response;
    } catch (error) {
      if (error.response) {
        // If there is a response from the server
        if (error.response.status === 400) {
          // Handle 400 Bad Request status code
          toast.warning("Bad Request: No items Left! :(");
        } else {
          // Handle other error status codes
          toast.error("Server Error: Something went wrong!");
        }
        // console.log(error.response.status);
      } else {
        // If no response from the server
        console.log(error.message);
        toast.error("Network Error: Something went wrong!");
      }
      return error.response.status;
    }
  };
};
