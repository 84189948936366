import React from "react";
import { Helmet } from "react-helmet";

const NotFoundPage = () => {
  return (
    <div style={{ minHeight: "100svh", backgroundColor: "#efefef" }}>
      <Helmet>
        <title>Fashion Zone | 404</title>
      </Helmet>
      <article
        className="d-flex flex-column"
        style={{
          margin: "100px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1>404 - Not Found</h1>
        <p>The page you are looking for does not exist.</p>
      </article>
    </div>
  );
};

export default NotFoundPage;
