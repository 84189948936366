import React, { useState, useEffect } from "react";
import "./styles/admin.css";
import Sidebar from "../components/Sidebar";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import {
  getAllProduct,
  deleteProduct,
  toggleProductRecommendation,
  getProduct,
} from "../../redux/apiCall/productCall";
import { productActions } from "../../redux/slice/productSlice";
import { IoStarOutline } from "react-icons/io5";
import { IoStarSharp } from "react-icons/io5";
import { IconButton } from "@mui/material";
import ProductsCard from "../components/ProductsCard";
import ProductModal from "../components/ProductModal";

const AdminProducts = () => {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.product);
  const [modalShow, setModalShow] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleView = async (productId) => {
    setLoading(true);
    try {
      const product = await dispatch(getProduct(productId));
      setSelectedProduct(product);
      setModalShow(true);
    } catch (error) {
      console.error("Failed to fetch product:", error);
      // Handle error, e.g., show a toast notification
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Product!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteProduct(id)).then(() => {
          dispatch(getAllProduct());
        });
      }
    });
  };

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "images",
      headerName: "image",
      width: 110,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          <img
            alt="product"
            src={params.row.images[0]?.path}
            width={100}
            height={100}
          />
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Product Name",
      width: 110,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "subcategoryName",
      headerName: "Subcategory",
      width: 120,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.row.subcategoryId?.name || "N/A",
    },
    {
      field: "price",
      headerName: "Price",
      width: 110,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div style={{ textAlign: "center" }}>$ {params.row.price}</div>
      ),
    },

    {
      field: "totalQuantity",
      headerName: "Quantity",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "isRecommended",
      headerName: "Recommended",
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            onClick={() =>
              handleToggleRecommendation(params.row._id, params.row.isHidden)
            }
          >
            {params.row.isRecommended ? (
              <IoStarSharp style={{ color: "#ff6016" }} />
            ) : (
              <IoStarOutline />
            )}
          </IconButton>
        </div>
      ),
    },

    {
      field: "Action",
      headerName: "Action",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      align: "center",
      headerAlign: "center",
      width: 260,
      renderCell: (params) => (
        <div>
          <button
            className="btn btn-success btn-sm"
            style={{ marginRight: "25px", borderRadius: "5px" }}
            onClick={() => handleView(params.row._id)}
          >
            View
          </button>

          <Link to={`/admin/product/edit`} state={{productId:params.row._id}}>
            <button
              className="btn btn-primary btn-sm"
              style={{ marginRight: "25px", borderRadius: "5px" }}
              // onClick={() => dispatch(getProduct(params.row._id))}
            >
              Edit
            </button>
          </Link>

          <button
            className="btn btn-danger btn-sm"
            style={{ borderRadius: "5px" }}
            onClick={() => handleDelete(params.row.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  // Map the products array to include an 'id' property for each row

  const rows = products.map((product) => {
    // console.log(product); // Log the current product being processed
    return {
      ...product,
      id: product._id, // Set the id to a unique value, e.g., the product's _id
    };
  });

  useEffect(() => {
    dispatch(getAllProduct());
  }, [dispatch]);

  const handleToggleRecommendation = async (id, isRecommended) => {
    await dispatch(toggleProductRecommendation(id, !isRecommended));
    await dispatch(getAllProduct());
  };

  const productsCount = products.length;

  useEffect(() => {
    dispatch(productActions.setProductsCount(productsCount));
  }, [dispatch, productsCount]);

  return (
    <article className="d-flex div-admin-dashboard-main">
      <Sidebar />
      <article className="d-flex flex-column div-admin-dashboard-main-child">
        <ProductsCard />
        <article className="d-flex justify-content-end">
          <Link to="/admin/product/add">
            <button className="admin-add-above-table-button">
              Add Product
            </button>
          </Link>
        </article>

        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowHeight={100}
            style={{ display: "flex", justifyContent: "center" }}
          />
        </div>
        <ProductModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          product={selectedProduct}
          loading={loading}
        />
      </article>
    </article>
  );
};

export default AdminProducts;
