import React from "react";
import "./styles/contact.css";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import FashionZoneLoc from "../Assets/fashionzone-loc.png";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const Contact = () => {
  const sendEmail = async (e) => {
    e.preventDefault();
    const serviceID = process.env.REACT_APP_SERVICE_ID;
    const publicKey = process.env.REACT_APP_PUBLIC_KEY;
    const templateID = process.env.REACT_APP_TEMPLATE_ID;

    try {
      const result = await emailjs.sendForm(
        serviceID,
        templateID,
        e.target,
        publicKey
      );
      // console.log(result.text);
      e.target.reset();
      toast.success("Email sent successfully!");
    } catch (error) {
      console.error(error.text);
      toast.error("Error sending email. Please try again.");
    }
  };

  return (
    <article className="contact-us-page-main-component">
      <Helmet>
        <title>Fashion Zone | Contact US</title>
      </Helmet>
      <article className="d-flex flex-row flex-wrap contact-us-page-main-logo-text">
        <article className="d-flex flex-column contact-us-page-main-logo-text-group">
          <article className="d-flex align-items-center justify-content-center">
            <FaPhone
              style={{ height: "30px", width: "30px", color: "black" }}
            />
          </article>
          <article className="d-flex align-items-center justify-content-center">
            +961 70 151 589
          </article>
        </article>

        <article className="d-flex flex-column contact-us-page-main-logo-text-group">
          <article className="d-flex align-items-center justify-content-center">
            <MdEmail
              style={{ height: "30px", width: "30px", color: "black" }}
            />
          </article>
          <article className="d-flex align-items-center justify-content-center">
            FashionZonelb@gmail.com
          </article>
        </article>

        <article className="d-flex flex-column contact-us-page-main-logo-text-group">
          <article className="d-flex align-items-center justify-content-center">
            <IoLocationSharp
              style={{ height: "30px", width: "30px", color: "black" }}
            />
          </article>
          <article className="d-flex align-items-center justify-content-center">
            Sabtiyeh, Baouchriye,
            <br /> Mont-Liban, Lebanon
          </article>
        </article>
      </article>

      {/*Form*/}
      <article className="d-flex flex-row contact-us-page-secondary-part">
        <form
          className="d-flex flex-column contact-us-page-secondary-part-form"
          onSubmit={sendEmail}
        >
          <article className="d-flex flex-row">
            <label className="d-flex">First Name</label>
            <input
              required
              type="text"
              className="d-flex"
              placeholder="Enter your first name"
              name="from_name"
            />
          </article>

          <article className="d-flex flex-row">
            <label>Last Name</label>
            <input
              required
              type="text"
              className="d-flex"
              placeholder="Enter your last name"
              name="from_lname"
            />
          </article>

          <article className="d-flex flex-row">
            <label>Email</label>
            <input
              required
              type="email"
              className="d-flex "
              placeholder="Enter your email"
              name="from_email"
            />
          </article>

          <article className="d-flex flex-row">
            <label className="contact-us-page-secondary-part-form-last-child">
              Message
            </label>
            <textarea
              required
              className="d-flex contact-us-page-secondary-part-form-last-child"
              placeholder="Type your message here"
              name="message"
            />
          </article>
          <article className="d-flex flex-row contact-us-article-submit-button">
            <button type="submit" className="contact-us-submit-button">
              Send Message
            </button>
          </article>
        </form>

        <article>
          <a
            href="https://www.google.com/maps/search/fashion+zone/@33.8818788,35.5544467,15.09z?entry=ttu"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="container contact-us-page-secondary-part-image"
              src={FashionZoneLoc}
              alt="Fashion Zone Location"
              style={{ width: "300", height: "350px" }}
            />
          </a>
        </article>
      </article>
    </article>
  );
};

export default Contact;
