import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import "./styles/navbar.css";
import logo from "../Assets/logooo.png";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { logoutUser } from "../redux/apiCall/authCall";
import Shopping from "./ShoppingCard";
import { FaUserCircle } from "react-icons/fa";
import "./styles/navbar.css";
import { CCollapse, CCard } from "@coreui/react";
import { IoIosArrowDown } from "react-icons/io";

const Navbar = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const { carts } = useSelector((state) => state.cart);

  const [activePage, setActivePage] = useState(
    localStorage.getItem("activePage")
  );
  // console.log(activePage)
  const { categories } = useSelector((state) => state.category);
  // console.log(categories);
  const handlePageChange = (page) => {
    setActivePage(page);
    localStorage.setItem("activePage", page);
  };
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const [anchorElNavCategory, setAnchorElNavCategory] = React.useState(null);

  const handleOpenNav = (event) => {
    setAnchorElNavCategory(event.currentTarget);
    setAnchorElNav(null);
  };
  const handleCloseNav = () => {
    setAnchorElNavCategory(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const closeNavMenu = () => {
    setVisible(false);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    closeNavMenu();
  }, [location]);

  if (
    location.pathname.includes("/admin")
    // location.pathname.includes("/login") ||
    // location.pathname.includes("/register")
  ) {
    return null; // Don't render Navbar for admin routes
  }

  //items in cart
  const calculateTotalItems = () => {
    let totalItems = 0;
    carts.forEach((item) => {
      totalItems += item.quantity;
    });
    return totalItems;
  };

  // Calculate the total quantity
  const totalItems = calculateTotalItems();

  return (
    <article position="static" className="class-to-remove-shadow min-vw-100">
      <article
        className="Navbar-main-Containerr min-vw-100 p-1"
        style={{ maxWidth: "xl" }}
      >
        <Toolbar disableGutters>
          <NavLink to="/">
            <img
              src={logo}
              alt=""
              width={60}
              height={60}
              className="img-logo-navbar-up-right-cr7 p-1"
            />
          </NavLink>

          <Box
            className="menu-list-navbar-mobile list-logo-navbar-up-right-cr7 "
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, ml: 23 }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Link
                  to="/"
                  className={`Menu-Navbar-Component-main nav-item text-dark ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                  onClick={() => handlePageChange("")}
                >
                  <Typography textAlign="center" className="typo-navbar-color ">
                    Home
                  </Typography>
                </Link>
              </MenuItem>

              <MenuItem
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  setVisible(!visible);
                }}
                className="css-1uwgr7b-MuiTypography-root"
              >
                Products
                <IoIosArrowDown style={{ marginLeft: "6px" }} />
              </MenuItem>

              <CCollapse visible={visible}>
                <CCard className="mt-1 collapse-list-body">
                  <article onClick={handleCloseNavMenu}>
                    <Link
                      to="/allproducts"
                      className={`Menu-Navbar-Component-main nav-item bg-dark text-dark ${
                        location.pathname === "/allproducts" ? "active" : ""
                      }`}
                      onClick={() => handlePageChange("allproducts")} // Update handlePageChange to handle "allproducts"
                      sx={{
                        color:
                          location.pathname === "/allproducts" ? "#ff6016" : "",
                      }}
                    >
                      <div onClick={handleCloseNavMenu} style={{}}>
                        <Typography textAlign="center">All Products</Typography>
                      </div>
                    </Link>

                    {categories.map((category) => (
                      <Link
                        style={{ fontFamily: "regular" }}
                        key={category._id}
                        to={`/products/${category._id}`}
                        className={`Menu-Navbar-Component-main nav-item bg-dark text-dark ${
                          location.pathname === `/products/${category._id}`
                            ? "active"
                            : ""
                        }`}
                        onClick={() => handlePageChange(category._id)} // Passing category._id to handlePageChange
                        sx={{
                          color:
                            location.pathname === `/products/${category._id}`
                              ? "#ff6016"
                              : "",
                        }}
                      >
                        <div onClick={handleCloseNavMenu}>
                          <Typography textAlign="center">
                            {category.name}
                          </Typography>
                        </div>
                      </Link>
                    ))}
                  </article>
                </CCard>
              </CCollapse>

              <MenuItem onClick={handleCloseNavMenu}>
                <Link
                  to="/about"
                  className={`Menu-Navbar-Component-main nav-item text-dark ${
                    location.pathname === "/about" ? "active" : ""
                  }`}
                  onClick={() => handlePageChange("about")}
                >
                  <Typography textAlign="center">About us</Typography>
                </Link>
              </MenuItem>

              <MenuItem onClick={handleCloseNavMenu}>
                <Link
                  to="/contact"
                  className={`Menu-Navbar-Component-main nav-item text-dark ${
                    location.pathname === "/contact" ? "active" : ""
                  }`}
                  onClick={() => handlePageChange("contact")}
                >
                  <Typography textAlign="center" className="typo-navbar-color">
                    Contact us
                  </Typography>
                </Link>
              </MenuItem>

              {/* {user && user.length > 0 ? (
                <div key="user-menu">
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Link
                      to="/profile"
                      className={`Menu-Navbar-Component-main nav-item text-dark ${
                        location.pathname === "/profile" ? "active" : ""
                      }`}
                      onClick={() => handlePageChange("profile")}
                    >
                      <Typography textAlign="center">Profile</Typography>
                    </Link>
                  </MenuItem>

                  <MenuItem onClick={handleCloseNavMenu}>
                    <Link
                      to="/myorders"
                      className={`Menu-Navbar-Component-main nav-item text-dark ${
                        activePage === "orders" ? "active" : ""
                      }`}
                      onClick={() => handlePageChange("orders")}
                    >
                      <Typography textAlign="center">My Orders</Typography>
                    </Link>
                  </MenuItem>

                  <MenuItem onClick={handleCloseNavMenu}>
                    <Link
                      to="/"
                      className={`Menu-Navbar-Component-main nav-item ${
                        activePage === "logout" ? "active" : ""
                      }`}
                      onClick={() => dispatch(logoutUser())}
                    >
                      <Typography
                        style={{ color: "#000000DE" }}
                        textAlign="center"
                      >
                        Log Out
                      </Typography>
                    </Link>
                  </MenuItem>
                </div>
              ) : (
                // Your other code for the 'else' case goes here
                <NavLink to="/login">
                  <MenuItem onClick={handleCloseNavMenu}>
                    <button type="button" className="admin-login-button  ml-10">
                      Login
                    </button>
                  </MenuItem>
                </NavLink>
              )} */}
            </Menu>
            <div style={{ cursor: "pointer" }}>
              <Shopping />
              {totalItems > 0 && (
                <div className="small-circle rounded-circle d-flex">
                  {totalItems}
                </div>
              )}
            </div>
          </Box>

          <Box
            className="Menu-Navbar-Component-main"
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
            }}
          >
            <Link to="/">
              <Button
                className={`Menu-Navbar-Component-main nav-item ${
                  location.pathname === "/" ? "active" : ""
                }`}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: location.pathname === "/" ? "#FFA500" : "#ffffff",
                  display: "block",
                }}
              >
                Home
              </Button>
            </Link>
            <article className="d-flex flex-column">
              <Button
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  setVisible(!visible);
                  handleCloseNavMenu();
                }}
                className={`Menu-Navbar-Component-main nav-item ${
                  location.pathname === "/products" ? "active" : ""
                }`}
                aria-controls="products-menu"
                aria-haspopup="true"
                sx={{ my: 2, color: "#ffffff", display: "block" }}
              >
                Products
                <IoIosArrowDown style={{ marginLeft: "6px" }} />
              </Button>
              <CCollapse visible={visible}>
                <CCard className="mt-1 collapse-list-body collapse-list-body-desktop">
                  <article onClick={handleCloseNav}>
                    <Link
                      to="/allproducts"
                      className={`Menu-Navbar-Component-main nav-item bg-dark text-white ${
                        location.pathname === "/allproducts" ? "active" : ""
                      }`}
                      onClick={() => handlePageChange("allproducts")} // Update handlePageChange to handle "allproducts"
                      sx={{
                        color:
                          location.pathname === "/allproducts" ? "#ff6016" : "",
                      }}
                    >
                      <div
                        onClick={() => {
                          handlePageChange("allproducts");
                          setVisible(false);
                        }}
                      >
                        <Typography textAlign="center">All Products</Typography>
                      </div>
                    </Link>

                    {categories.map((category) => (
                      <Link
                        key={category._id}
                        to={`/products/${category._id}`}
                        className={`Menu-Navbar-Component-main nav-item bg-dark text-white ${
                          location.pathname === `/products/${category._id}`
                            ? "active"
                            : ""
                        }`}
                        onClick={() => {
                          handlePageChange(category._id);
                          setVisible(false);
                        }} // Passing category._id to handlePageChange
                        sx={{
                          color:
                            location.pathname === `/products/${category._id}`
                              ? "#ff6016"
                              : "",
                        }}
                      >
                        <div onClick={handleCloseNavMenu}>
                          <Typography textAlign="center">
                            {category.name}
                          </Typography>
                        </div>
                      </Link>
                    ))}
                  </article>
                </CCard>
              </CCollapse>
            </article>
            <Link to="/about">
              <Button
                className={`Menu-Navbar-Component-main nav-item ${
                  location.pathname === "/about" ? "active" : ""
                }`}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "#ffffff", display: "block" }}
              >
                About us
              </Button>
            </Link>

            <Link to="/contact">
              <Button
                className={`Menu-Navbar-Component-main nav-item ${
                  location.pathname === "/contact" ? "active" : ""
                }`}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "#ffffff", display: "block" }}
              >
                Contact us
              </Button>
            </Link>
          </Box>

          <Box sx={{ flexGrow: 0, paddingLeft: 1 }}>
            <article className="d-flex flex-row">
              <div
                className="shopping-cart-component-style d-flex align-items-center px-4"
                style={{ cursor: "pointer" }}
              >
                <Shopping />
                {totalItems > 0 && (
                  <div className="small-circle rounded-circle d-flex">
                    {totalItems}
                  </div>
                )}
              </div>
            </article>
          </Box>
        </Toolbar>
      </article>
    </article>
  );
};
export default Navbar;
