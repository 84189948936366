import { createSlice } from "@reduxjs/toolkit";

const initialProductCount = parseInt(localStorage.getItem("productCount")) || 0;

const productSlice = createSlice({
  name: "product",
  initialState: {
    products: [],
    isProductCreated: false,
    product: null,
    productsCount: initialProductCount,
    productsBySubcategory: [], // new state property for products by categoryId
    recommendedProducts: 0,
    sizeDetails: {},
    recommendedCarousel: [],
    allProducts: [],
  },
  reducers: {
    setProducts(state, action) {
      state.products = action.payload;
    },
    setProductsCount(state, action) {
      state.productsCount = action.payload;
    },
    setProduct(state, action) {
      state.product = action.payload;
    },
    setAllProducts(state, action) {
      state.allProducts = action.payload;
    },

    setRecommendedCarousel(state, action) {
      state.recommendedCarousel = action.payload;
    },
    setRecommendedProductsCount(state, action) {
      state.recommendedProducts = action.payload;
    },
    updateProductCount(state, action) {
      state.productsCount = action.payload;
    },
    createProduct(state, action) {
      state.products.push(action.payload);
      state.isProductCreated = true;
    },

    deleteProducts(state, action) {
      state.products = state.products.filter((p) => p._id !== action.payload);
    },
    editProducts(state, action) {
      state.products = state.products.filter((p) => p._id !== action.payload);
    },
    setProductsBySubcategory(state, action) {
      state.productsBySubcategory = action.payload;
    },
    setSizeDetails: (state, action) => {
      state.sizeDetails = action.payload;
    },
  },
});

const productReducer = productSlice.reducer;
const productActions = productSlice.actions;

export { productActions, productReducer };
