import axios from "axios";
import { toast } from "react-toastify";
import { subcategoryActions } from "../slice/subcategorySlice";
import Cookies from "js-cookie";
import { CiAlignRight } from "react-icons/ci";

export const getAllSubcategories = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        "https://67.223.117.107/api/subcategory",
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("userToken")}`,
          },
        }
      );
      // console.log("first", response.data.data);
      dispatch(subcategoryActions.setSubcategories(response.data.data));
      dispatch(
        subcategoryActions.setSubcategoriesCount(response.data.data.length)
      );
      // Filter hidden subcategories
      const hiddenSubcategories = response.data.data.filter(
        (subcategory) => subcategory.isHidden
      );
      // Dispatch the count of hidden subcategories
      dispatch(
        subcategoryActions.setHiddenSubcategoriesCount(
          hiddenSubcategories.length
        )
      );
    } catch (error) {
      console.log(error);
      toast.error("Bad connection!");
    }
  };
};

export const getAllSubCategoriesByCategoryId =
  (categoryId) => async (dispatch) => {
    // console.log(`Fetching subcategories for category ID: ${categoryId}`);

    try {
      const response = await axios.get(
        `https://67.223.117.107/api/subcategory/subcategories/category/${categoryId}`
      );
      dispatch(
        subcategoryActions.setSubcategoriesByCategoryId(response.data.data)
      );
      // console.log("response by category id  ", response.data.data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

export const toggleSubcategoryVisibility = (subcategoryId, isHidden) => {
  return async () => {
    try {
      const response = await axios.put(
        `https://67.223.117.107/api/subcategory/${subcategoryId}/ishidden`,
        { isHidden },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("userToken")}`,
          },
        }
      );
      // console.log("Visibility toggled successfully", response);
    } catch (error) {
      console.log(error);
      toast.error("Error toggling visibility!");
    }
  };
};

export const deleteSubcategory = (subcategoryId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(
        `https://67.223.117.107/api/subcategory/delete/${subcategoryId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("userToken")}`,
          },
        }
      );
      // console.log("data", data);
      dispatch(subcategoryActions.deleteSubcategory(subcategoryId));
      toast.success("Subcategory deleted successfully.");
    } catch (error) {
      toast.error("Please try again later.");
    }
  };
};

export const editSubcategory = (subcategoryId, formData) => {
  return async (dispatch) => {
    try {
      // Convert formData to a JSON object
      const jsonData = {
        name: formData.get("name"),
        categoryId: formData.get("categoryId"),
      };

      const response = await axios.put(
        `https://67.223.117.107/api/subcategory/${subcategoryId}`,
        jsonData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("userToken")}`,
          },
        }
      );
      // Log the response object
      // console.log(response);

      dispatch(subcategoryActions.editSubcategory(response.data));
      toast.success("Subcategory edited successfully.");
    } catch (error) {
      console.error("Error editing subcategory:", error);
      toast.error("Failed to edit subcategory. Please try again later.");
      throw error;
    }
  };
};

export const addSubcategory = (subcategoryData) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        "https://67.223.117.107/api/subcategory/",
        subcategoryData,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("userToken")}`,
          },
        }
      );
      dispatch(subcategoryActions.addSubcategory(response.data.data));
      toast.success("Subcategory added successfully.");

      return response.data.data;
    } catch (error) {
      console.error("Error creating subcategory:", error);
      throw error;
    }
  };
};
