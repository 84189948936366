import React from "react";
import "./styles/terms.css";
import { Helmet } from "react-helmet";

const TermsAndConditions = () => {
  return (
    <article style={{ backgroundColor: "#efefef", width: "100%" }}>
      <Helmet>
        <title>Fashion Zone | Terms & Conditions</title>
      </Helmet>
      <div className="Terms-and-condition-main-container d-flex flex-column mt-5">
        <div className="single-title-with-paragraph d-flex flex-column ">
          <h4 className="d-flex gap-2 p-classname-title ">
            Terms <span style={{ color: "#ff6016" }}>&</span>
            <span className="span-class-name-main">Conditions</span>
          </h4>
          <span className="text-muted span-for-date-updated">
            Last Updated March 3rd 2024{" "}
          </span>
          <hr />

          <div className="single-paragraph-terms-conditions">
            <h3>1. Acceptance of Terms</h3>
            <p className="text-muted">
              By accessing or using the website of Fashion Zone, you agree to
              comply with and be bound by these terms and conditions. If you do
              not agree with any part of these terms and conditions, please do
              not use the Website.
            </p>
          </div>

          <div className="single-paragraph-terms-conditions">
            <h3>2. Products and Services</h3>
            <p className="text-muted">
              Fashion Zone offers a variety of clothing products through the
              Website. Product descriptions, availability, and prices are
              subject to change without notice.
            </p>
          </div>

          <div className="single-paragraph-terms-conditions">
            <h3>3. Orders and Payments</h3>
            <p className="text-muted">
              By placing an order through our Website, you agree to provide
              accurate and complete information for all purchases made. Payment
              methods and currency accepted will be clearly indicated during the
              checkout process.
            </p>
          </div>

          <div className="single-paragraph-terms-conditions">
            <h3>4. Intellectual Property</h3>
            <p className="text-muted">
              All content on the Website, including images, text, and logos, is
              the property of Fashion Zone and is protected by copyright and
              other intellectual property laws.
            </p>
          </div>

          <div className="single-paragraph-terms-conditions">
            <h3>5. Privacy Policy</h3>
            <p className="text-muted">
              Your privacy is important to us. Please review our Privacy Policy
              to understand how we collect, use, and safeguard your personal
              information.
            </p>
          </div>

          <div className="single-paragraph-terms-conditions">
            <h3>6. Governing Law</h3>
            <p className="text-muted">
              These terms and conditions are governed by and construed in
              accordance with the laws of Lebanon. Any dispute arising out of or
              related to these terms and conditions shall be subject to the
              exclusive jurisdiction of the courts located in Beirut, Lebanon.
            </p>
          </div>

          <div className="single-paragraph-terms-conditions">
            <h3>7. Changes to Terms and Conditions</h3>
            <p className="text-muted">
              Fashion Zone reserves the right to modify these terms and
              conditions at any time. It is your responsibility to review these
              terms periodically for changes.
            </p>
          </div>
        </div>
      </div>
    </article>
  );
};

export default TermsAndConditions;
