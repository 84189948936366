import React, { useEffect } from "react";
import { Carousel } from "primereact/carousel";
import { useDispatch, useSelector } from "react-redux";
import { getRecommendedProducts } from "../redux/apiCall/productCall";
import { Link } from "react-router-dom";

export default function ResponsiveDemo() {
  const dispatch = useDispatch();
  const { recommendedCarousel } = useSelector((state) => state.product);

  useEffect(() => {
    dispatch(getRecommendedProducts());
  }, [dispatch]);

  const productTemplate = (product) => {
    const firstImage = product.images[0];
    const isPhoneScreen = window.innerWidth <= 600; // Define phone screen width

    return (
      <div className="m-2 text-center py-5 px-3">
        <Link to={`/product/${product._id}`}>
          <div className="mb-3">
            <img
              src={firstImage.path}
              alt={product.name}
              className="shadow-2"
              style={{
                width: isPhoneScreen ? "120px" : "200px",
                height: isPhoneScreen ? "120px" : "200px",
              }}
            />
          </div>
          <div>
            <h4 className="mb-1 text-black">{product.name}</h4>
            <h6 className="mt-0 mb-3 text-muted">
              {product.subcategoryId.name}
            </h6>
          </div>
        </Link>
      </div>
    );
  };

  // Determine the number of visible items based on screen width
  const numVisible =
    window.innerWidth <= 600
      ? 1
      : window.innerWidth >= 701 && window.innerWidth <= 1100
      ? 2
      : 4;

  return (
    <article style={{ backgroundColor: "#efefef" }}>
      <div>
        <Carousel
          value={recommendedCarousel}
          numScroll={1}
          numVisible={numVisible} // Adjust number of visible items based on screen width
          itemTemplate={productTemplate}
        />
      </div>
    </article>
  );
}
